import React,{useState,useEffect} from 'react'
import Axios from 'axios'
import Swal from 'sweetalert2';
import {useAlumno} from '../../../Context/alumnoContext'
import {gql,useQuery,useMutation,useLazyQuery} from '@apollo/client'
import REG_EX from '../../../Helpers/regex';

export const useTramite = ()=>{
    const {usuario} = useAlumno()
    const [objetoDinamico,setObjetoDinamico] = React.useState({usuario})

    const actualizarObjetoDinamico = (campoNuevo)=>{
        setObjetoDinamico({...objetoDinamico,...campoNuevo})
    }

    const CONSULTAR_TRAMITES_POR_TIPO_MINISTRO = gql`
        query consultaTramites($tipo:String!,$usuario_id:Int!){
            tramitesTipoMinistro(tipo:$tipo,id_ministro:$usuario_id){
                id,
                codigo_web,
                fecha,
                status{
                    texto
                }
            }
        }
    `

const CONSULTAR_ASCENSOS_POR_MINISTRO = gql`
query consultaAscensos($ministro_id:Int!){
    ascensosPorMinistro(ministro_id:$ministro_id){
        status_actual,
        id_tramite{
            codigo_web,status{texto}
        }
    }
}
`

const CONSULTAR_ASCENSO_VIGENTE = gql`
query consultaAscensoVigente($ministro_id:Int!){
    ascensoSolicitado(id:$ministro_id)
}
`

const GET_ASCENSO = gql`
query ascenso ($id:ID!){
    ascensoInfo(id:$id){
        status_actual,
        ministro,
        ministro_id,
        analiticoURL,
        id_tramite{
            distrito{id,nombre},
            region
        }
    }
}
`

    const CREAR_ASCENSO = gql`
mutation nuevoAscenso($status_actual:String!,
                      $region:Int!,
                      $distrito:String!,
                      $distrito_id:Int!,
                      $usuario: String!,
                      $usuario_id:Int!,
                      $analiticoURL:String!,
                      $extra_1URL:String,
                      $ministro:String!,
                      $descripcion:String!,
                      $ministro_id:Int!){
                            nuevoAscenso(
                                status_actual:$status_actual,
                                region:$region,
                                distrito:$distrito,
                                distrito_id:$distrito_id,
                                ministro:$ministro,
                                ministro_id:$ministro_id,
                                solicitante:$usuario,
                                solicitante_id:$usuario_id,
                                analiticoURL:$analiticoURL,
                                extra_1URL:$extra_1URL,
                                descripcion:$descripcion
                            )}`;

const callback = (values)=>{
        const variables = {
            status_actual:values.status_actual,
            region:Number(values.id_region),
            distrito:values.distrito,
            distrito_id:Number(values.id_distrito),
            ministro:values.ministro,
            ministro_id:Number(values.id_ministro),
            usuario:usuario.nombre,
            descripcion:`Solicita ascenso de ${values.ministro}`,
            usuario_id:Number(usuario.id_obrero),
            analiticoURL:values.analiticoURL, // viene del callbackPrevio
            extra_1URL:values.extra_1URL // viene del callbackPrevio
        }
 return variables
}

/*const {loading,error,data,refetch} = useQuery(CONSULTAR_TRAMITES_POR_TIPO_MINISTRO,
    {variables:{usuario_id:usuario.id_obrero,tipo:'ascenso'},
//    pollInterval: 5000000
})
*/
const [listadoTramitesporMinistro,{error,data,loading}] = useLazyQuery(CONSULTAR_TRAMITES_POR_TIPO_MINISTRO,
    {variables:{usuario_id:usuario.id_obrero,tipo:'ascenso'}})

const [updateAscenso,{error:error2,data:ascensoInfo,loading:loading2}] = useLazyQuery(GET_ASCENSO)    

const [ascensosPorMinistroDetalle] = useLazyQuery(CONSULTAR_ASCENSOS_POR_MINISTRO) // las variables las paso como un objeto 
                                                                                    // al ejecutar

const [ascensoVigente] = useLazyQuery(CONSULTAR_ASCENSO_VIGENTE);

    const campos_formulario = [
                /*{
             name:'ministro_id',
             required:true,
             visible:true,
             initial_value:'',
             type:'copy',
             REG_EX:REG_EX.NUMERIC,
             label:'ministro_id',
             grupo:'Ministro'
         },*/
         {   
            name:'id_ministro',
            required:true,
            visible:true,
            disabled:true,
            initial_value:'',
            length:100,
            type:'select',
            selectFirst:true,
            guardarTextoEnCampo:'ministro',
            allowed_values:async(props,objetoDinamico)=>vectorMinistroDinamico(objetoDinamico).map(item=>Object.values(item)),
//             label:'Rango actual',
            place:null,
            grupo:'Ministro',
           // style:{display:'none'}
        },  
        {   
            name:'status_actual',
            required:true,
            visible:true,
            disabled:true,
            initial_value:'',
            length:100,
            type:'select',
            //guardarTextoEnCampo:'status_actual',
            selectFirst:true,
//             allowed_values:async(props,objetoDinamico)=>[{id:objetoDinamico.usuario.rango,value:objetoDinamico.usuario.rango}].map(item=>Object.values(item)),
            allowed_values:async(props,objetoDinamico)=>vectorStatusDinamico(objetoDinamico).map(item=>Object.values(item)),
//             label:'Rango actual',
            place:null,
            grupo:'Rango actual',
            REG_EX:REG_EX.NUMERIC,
            //style:{display:'none'}
        },  
        {   
            name:'ministro',
            visible:false,
            required:true, // Es requerido pero no visible, se carga cada vez que cambia el select de distrito
            initial_value:'', 
        },
        /* {
             name:'ministro',
             required:true,
             visible:true,
             initial_value:'',
             type:'copy',
             REG_EX:REG_EX.ALPHABETIC,
             label:'Ministro',
             grupo:'Ministro'
         },*/
         {   
             name:'id_region',
             required:true,
             visible:true,
             disabled:true,
             initial_value:'',
             length:100,
             type:'select',
             selectFirst:true,
             allowed_values:async (props,objetoDinamico)=>{
                 try{
                     console.log('objetoDinamico',objetoDinamico)
                     const {data} = await Axios.get('/api/tablasgenerales/regiones') 
                     return data.filter(callbackFilterDinamicoRegiones(objetoDinamico))
                     .map(item=>{return {id:item.id_region,nombre:item.nombre}})
                     .map(item=>{
                         return Object.values(item)
                     })
                 }catch(err){
                     console.log(err)
                     alert('Se produjo un error al cargar las regiones')
                 }
             },
             label:'Región',
             place:null,
             grupo:'Región y distrito actuales',
             REG_EX:REG_EX.NUMERIC
         },   
         {   
             name:'id_distrito',
             required:true,
             visible:true,
             disabled:true,
             initial_value:'',
             allowedIf:'id_region',
             length:100,
             dependeDe:'id_region', // ATENCION: como dependeDe esta id_region este atributo se lee en el SELECT para volver a llamar a allowed_values
             type:'select',
             guardarTextoEnCampo:'distrito',
             allowed_values:async (props,objetoDinamico)=>{

                 try{
                     const {data} = await Axios.get(`/api/tablasgenerales/distritos/${props.values.id_region || 1}`)
                     return data.filter(callbackFilterDinamicoDistrito(objetoDinamico))
                     .map(item=>{return {id:item.id_distrito,nombre:item.nombre}})
                     .map(item=>{
                         return Object.values(item)
                     })
                 }catch(err){
                     console.log(err)
                     alert('Se produjo un error al cargar las regiones')
                 }
             },
             label:'Distrito',
             place:null,
             grupo:'Región y distrito actuales',
             REG_EX:REG_EX.NUMERIC
         },  
         {   
             name:'distrito',
             visible:false,
             required:true, // Es requerido pero no visible, se carga cada vez que cambia el select de distrito
             initial_value:'', 
         }

]

    return {
        usuario,
        mutationPadre:CREAR_ASCENSO,
        variables:callback,
        listadoTramitesporMinistro,
        objetoDinamico,
        actualizarObjetoDinamico,
        campos_formulario,
        ascensosPorMinistroDetalle,
        updateAscenso,
        ascensoVigente
    }
}

const callbackFilterDinamicoRegiones = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return (item)=>item.id_region==objetoDinamico.otroMinistro.id_region
    }else{
        return (item)=>item.id_region==objetoDinamico.usuario.id_region
    }
}

const callbackFilterDinamicoDistrito = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return (item)=>item.id_distrito==objetoDinamico.otroMinistro.id_distrito
    }else{
        return (item)=>item.id_distrito==objetoDinamico.usuario.id_distrito
    }
}

const vectorStatusDinamico = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return [{id:objetoDinamico.otroMinistro.rango,value:objetoDinamico.otroMinistro.rango}]
    }else{
        return [{id:objetoDinamico.usuario.rango,value:objetoDinamico.usuario.rango}]
    }
}

const vectorMinistroDinamico = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return [{id:objetoDinamico.otroMinistro.id_ministro,value:objetoDinamico.otroMinistro.ministro}]
    }else{
        return [{id:objetoDinamico.usuario.id_obrero,value:objetoDinamico.usuario.nombre}]
    }
}
