import React, {useEffect,useState,useContext} from 'react'
import { Box,Typography,Button, FormControlLabel, FormGroup,Checkbox } from "@material-ui/core";
import Divider from '@mui/material/Divider';
import { AlertDialog } from '../../../componentes/MUI/AlertDialog';
import {FormularioTramite} from '../../FormularioTramite'
import {TramiteContextProvider} from '../contexto/tramiteContext'
import {tramiteContext} from '../contexto/tramiteContext'
import {useTramite} from '../hooks/useTramite'
import { DropzoneArea } from "material-ui-dropzone";
import { SubirArchivo } from '../../../componentes/formulariosFormik/SubirArchivo';
import Axios from 'axios';
import {LoaderCircular} from '../../../componentes/LoaderCircular'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import {useAlumno} from '../../../Context/alumnoContext'
import { faWindowClose,faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Iglesias} from '../componentes/Iglesias'
import {UbicacionInicial} from '../componentes/UbicacionInicial'
import {UbicacionIglesia} from '../componentes/UbicacionIglesia'
import {DatosPersonales} from '../componentes/DatosPersonales'
import {Contacto} from '../componentes/Contacto'
import {Cuestionario} from '../componentes/Cuestionario'
import {Cuestionario2} from '../componentes/Cuestionario2'
import {Pastores} from '../componentes/Pastores'
import {Referencias} from '../componentes/Referencias'
import Alert from '@mui/material/Alert';
import moment from 'moment';
import regex from '../../../Helpers/regex'
import { isPossiblePhoneNumber,isValidPhoneNumber  } from 'react-phone-number-input'
import {doPostFile,eliminarArchivos, hacerFoco} from '../../../Helpers/utilidades-globales'
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import Estatutos from '../../../componentes/Estatutos';
import Compromiso from '../../../componentes/Compromiso';
import Doctrina from '../../../componentes/Doctrina';
import Aceptaciones1raCredencial from '../../../componentes/Aceptaciones1raCredencial';
 
export const InicioTramite = ({finalizar,datosPatrocinante,cancelar})=>{
    const [loadingFile,setLoadingFile] = React.useState(false);
    const {usuario,snackbar} = useAlumno();
    const {campos_formulario,provincias,partidos,tiposDoc,nacionalidades,estadosCiviles,fechaEsValida,dniEstaEnUso,keyNoValida} = useTramite();
    const [objetoDinamico,setObjetoDinamico] = React.useState({usuario,paso1:null,nacionalidad:{id:3,nombre:'Argentina'}});
    const [continuar,setContinuar]=useState(false);
    const [paso_ubicacion_inicial_ok,set_paso_ubicacion_inicial_ok]=useState(false);
    const [paso_datos_personales_ok,set_paso_datos_personales_ok]=useState(false);
    const [paso_contacto_ok,set_paso_contacto_ok]=useState(false);
    const [paso_cuestionario_ok,set_paso_cuestionario_ok]=useState(false);
    const [paso_cuestionario2_ok,set_paso_cuestionario2_ok]=useState(false);
    const [paso_pastores_ok,set_paso_pastores_ok]=useState(false);
    const [paso_referencias_ok,set_paso_referencias_ok]=useState(false);
    const [validarCampos,setvalidarCampos]=useState(false);
    const [analiticoFile, setAnaliticoFile] = React.useState(null);
    const [dniFrenteFile, setDniFrenteFile] = React.useState(null);
    const [dniReversoFile, setDniReversoFile] = React.useState(null);
    const [foto4x4File, setFoto4x4File] = React.useState(null);
    const [antecedentes_penFile, setAntecedentesPenFile] = React.useState(null);
    const [certificadoMatrimonioFile, setCertificadoMatrimonioFile] = React.useState(null);
    const [documento,setDocumento] = React.useState(null);
    const [lecturas,setLecturas] = React.useState({});
    const [otroArchivo, setOtroArchivo] = React.useState(null);

    let adjuntos = [];

    useEffect(()=>{
      /*setObjetoDinamico({...objetoDinamico,nombre:datosPatrocinante.nombre, 
        apellido:datosPatrocinante.apellido,
        solicitante:datosPatrocinante.id_tramite.ministro.nombre,
        id_solicitante:datosPatrocinante.id_tramite.ministro.id,
        email:datosPatrocinante.email,
        id_region:datosPatrocinante.id_tramite.region,
        distrito:{id:datosPatrocinante.id_tramite.distrito.id,nombre:datosPatrocinante.id_tramite.distrito.nombre},
        sexo:datosPatrocinante.sexo,
        //provincia:{id:datosPatrocinante.provincia.id,nombre:datosPatrocinante.provincia.nombre},
        //partido:{id:datosPatrocinante.partido.id,nombre:datosPatrocinante.partido.nombre},
        provinciaigl:{id:datosPatrocinante.provincia.id,nombre:datosPatrocinante.provincia.nombre},
        partidoigl:{id:datosPatrocinante.partido.id,nombre:datosPatrocinante.partido.nombre},
        iglesia:{id:datosPatrocinante?.iglesia?.id,nombre:datosPatrocinante?.iglesia?.nombre},
        id_tramite_patrocinio:datosPatrocinante._id,
        doctrina:false,
        compromiso:null,
        estatutos:null,
        nombrepastor:'CARLOS ROJAS',nombreanciano:'FABIAN PLACANICA',emailpastor:'pablo.david.st@gmail.com',
        emailanciano:'pablo.david.st@gmail.com',
        dni:'23863311',
        celular:'11-5944-3002',
        telefono:'11-5944-3002',
        sostenimiento:'voy a trabajar todos los días',
        diezmo:'SI',pastores:['CARLOS NARANJO'],
        bautismo_es:'NO',
        //fecha_bautismo_a:'1995-01-01',
        //fecha_conversion:'1990-01-01',
        fecha_nacimiento:'1974-03-27',
        tipo_doc:{id:1,nombre:'DNI'},
        estado_civil:{id:4,nombre:'Soltero'}
      })*/
        setObjetoDinamico({...objetoDinamico,nombre:datosPatrocinante.nombre, 
                                              apellido:datosPatrocinante.apellido,
                                              solicitante:datosPatrocinante.id_tramite.ministro.nombre,
                                              id_solicitante:datosPatrocinante.id_tramite.ministro.id,
                                              email:datosPatrocinante.email,
                                              id_region:datosPatrocinante.id_tramite.region,
                                              distrito:{id:datosPatrocinante.id_tramite.distrito.id,nombre:datosPatrocinante.id_tramite.distrito.nombre},
                                              sexo:datosPatrocinante.sexo,
                                              //provincia:{id:datosPatrocinante.provincia.id,nombre:datosPatrocinante.provincia.nombre},
                                              //partido:{id:datosPatrocinante.partido.id,nombre:datosPatrocinante.partido.nombre},
                                              provinciaigl:{id:datosPatrocinante.provincia.id,nombre:datosPatrocinante.provincia.nombre},
                                              partidoigl:{id:datosPatrocinante.partido.id,nombre:datosPatrocinante.partido.nombre},
                                              iglesia:{id:datosPatrocinante?.iglesia?.id,nombre:datosPatrocinante?.iglesia?.nombre},
                                              id_tramite_patrocinio:datosPatrocinante._id,
                                              doctrina:null,
                                              compromiso:null,
                                              estatutos:null,
                                            })
        hacerFoco('b-volver')
    },[])



    const callbackPrevio = async ()=>{

      setLoadingFile(true)

      if(!analiticoFile)
      {
        setLoadingFile(false)
        return [false,'Falta adjuntar el archivo de analítico']
      }

      if(!dniFrenteFile)
      {
        setLoadingFile(false)
        return [false,'Falta adjuntar el archivo con la foto del DNI (Frente)']
      }

      if(!dniReversoFile)
      {
        setLoadingFile(false)
        return [false,'Falta adjuntar el archivo con la foto del DNI (Reverso)']
      }

      if(!foto4x4File)
      {
        setLoadingFile(false)
        return [false,'Falta adjuntar la foto 4 x 4']
      }

      if(!antecedentes_penFile)
      {
        setLoadingFile(false)
        return [false,'Falta adjuntar el certificado de antecedentes penales']
      }

      if((objetoDinamico.estado_civil.id == 3 || objetoDinamico.estado_civil.id==6)){
          if(!certificadoMatrimonioFile)
          {
            setLoadingFile(false)
            return [false,'Falta adjuntar el certificado de matrimonio']
          }
      }

      try{

        const resultado_analitico = await doPostFile(analiticoFile,{id_obrero:objetoDinamico.dni},'analitico1cred','pdf')
        
        adjuntos.push(resultado_analitico[1] || 'unknown')

        if(!resultado_analitico[0])
        {
          setLoadingFile(false)
          eliminarArchivos(adjuntos)
          return [false,resultado_analitico[1]]
        }
        
        setLoadingFile(false)
  
        const resultado_dni_frente = await doPostFile(dniFrenteFile,{id_obrero:objetoDinamico.dni},'dni_frente1cred','pdf')
        adjuntos.push(resultado_dni_frente[1] || 'unknown')

        if(!resultado_dni_frente[0])
        {
          setLoadingFile(false)
          eliminarArchivos(adjuntos)
          return [false,resultado_dni_frente[1]]
        }
        
        setLoadingFile(false)

        const resultado_dni_reverso = await doPostFile(dniReversoFile,{id_obrero:objetoDinamico.dni},'dni_reverso1cred','pdf')
        adjuntos.push(resultado_dni_reverso[1] || 'unknown')

        if(!resultado_dni_reverso[0])
        {
          setLoadingFile(false)
          eliminarArchivos(adjuntos)
          return [false,resultado_dni_reverso[1]]
        }
        
        setLoadingFile(false)

        let adjuntos_aux = {}

        const resultado_foto4x4 = await doPostFile(foto4x4File,{id_obrero:objetoDinamico.dni},'foto_4x4cred','pdf')
        adjuntos.push(resultado_foto4x4[1] || 'unknown')


        if(!resultado_foto4x4[0])
        {
          setLoadingFile(false)
          eliminarArchivos(adjuntos)
          return [false,resultado_foto4x4[1]]
        }else{
          Object.assign(adjuntos_aux,{foto_4x4URL:resultado_foto4x4[1]})
        }
        
        setLoadingFile(false)

        const resultado_antecedentes_pen = await doPostFile(antecedentes_penFile,{id_obrero:objetoDinamico.dni},'antecedentes_pencred','pdf')
        adjuntos.push(resultado_antecedentes_pen[1] || 'unknown')


        if(!resultado_antecedentes_pen[0])
        {
          setLoadingFile(false)
          eliminarArchivos(adjuntos)
          return [false,resultado_antecedentes_pen[1]]
        }else{
          Object.assign(adjuntos_aux,{antecedentes_penURL:resultado_antecedentes_pen[1]})
        }
        
        setLoadingFile(false)
  
        if(certificadoMatrimonioFile){
            const resultado_cert_mat = await doPostFile(certificadoMatrimonioFile,{id_obrero:objetoDinamico.dni},'cert_matcred','pdf')
            adjuntos.push(resultado_cert_mat[1] || 'unknown')
    
            if(!resultado_cert_mat[0])
            {
              setLoadingFile(false)
              eliminarArchivos(adjuntos)
              return [false,resultado_cert_mat[1]]
            }else{
              Object.assign(adjuntos_aux,{cert_matURL:resultado_cert_mat[1]})
            }
            
            setLoadingFile(false)
        }
        
        let resultado_otroArchivo;

        if(otroArchivo){
          resultado_otroArchivo = await doPostFile(otroArchivo,{id_obrero:objetoDinamico.dni},'extra_1cred','pdf')
          adjuntos.push(resultado_otroArchivo[1] || 'unknown')

           if(!resultado_otroArchivo[0])
           {
             setLoadingFile(false)
             eliminarArchivos(adjuntos)
             return [false,resultado_otroArchivo[1]]
           }else{
            Object.assign(adjuntos_aux,{extra_1URL:resultado_otroArchivo[1]})
          }
          
        }

        return [true,{
          analiticoURL:resultado_analitico[1],
          dni_frenteURL:resultado_dni_frente[1],
          dni_reversoURL:resultado_dni_reverso[1],
          ...adjuntos_aux
          //ministro:'Prueba testing',    // AQUI PUEDO ENVIAR TODO TIPO DE DATOS QUE NO ESTAN EN EL
          //id_ministro:33                // FORMULARIO PERO QUE DESEAMOS QUE LLEGUEN A LA MUTACIÓN
                                          // O QUE ESTAN EN EL FORM PERO QUEREMOS QUE SE PISEN
        }]

      }catch(err){
        setLoadingFile(false)
        console.log(err)
        eliminarArchivos(adjuntos)
        return [false,err?.message || 'Se produjo un error al ejecutar al inicializar el proceso de guardado de datos']
      }

  } 

    const callbackPrevioOLD = async ()=>{

      /*
      // Validación de datos a enviar a la mutación  + values del formik
      const validacionEncargado = validarEncargado(encargado);
      
      if(validacionEncargado[0]==false){
          return[false,validacionEncargado[1]]
      }

       // Envío de datos a la mutación + values del formik
       return [true,{
        iglesia_madre:infoIglesiaMadre.nombre_igl,
        iglesia_madre_id:infoIglesiaMadre.id_iglesia,
        encargadoEsUad:encargado.encargadoEsUad,
        nombreEncargado:encargado.nombreEncargado,
        id_pastor_uad:encargado.id_pastor_uad
      }]
     
      */

      // por default devuelva true

       return [true]
    } 

   
    const validarCondicionesIniciales = ()=>{
      // agregar validaciones si las hay
      return [true]
    }

    const iniciarContinuar = ()=>{
          
      if(!validarCondicionesIniciales()[0]){
        alert(validarCondicionesIniciales()[1])
        return
      }
      setContinuar(true)
    }

    const validarCondicionesInicialesPaso1_old = ()=>{

      const validaciones = [];

      if(objetoDinamico?.nombre==undefined || !objetoDinamico?.nombre){
        return [false,'Falta identificar el nombre del nuevo ministro','nombre']
      }

      if(objetoDinamico?.apellido==undefined || !objetoDinamico?.apellido){
        return [false,'Falta identificar el apellido del nuevo ministro','apellido']
      }

     if(objetoDinamico?.tipo_doc==undefined){
        return [false,'Falta identificar el tipo de documento del nuevo ministro','tipo_documento']
      }

      if(objetoDinamico?.dni==undefined || !objetoDinamico?.dni){
        return [false,'Falta identificar el número de documento del nuevo ministro','dni']
      }

      if(objetoDinamico?.fecha_nacimiento==undefined || !objetoDinamico?.fecha_nacimiento){
        return [false,'Falta identificar la fecha de nacimiento del nuevo ministro','fecha_nacimiento']
      }else{
        
        if(!(fechaEsValida(objetoDinamico?.fecha_nacimiento))){
          console.log('La fecha de naciminento no es válida')
          return [false,'La fecha de naciminento no es válida','fecha_nacimiento']
        }
      }

      if(objetoDinamico?.nacionalidad==undefined){
        return [false,'Falta identificar la nacionalidad del nuevo ministro','nacionalidad']
      }

      if(objetoDinamico?.nacionalidad?.id==undefined){
        return [false,'Falta identificar la nacionalidad del nuevo ministro','nacionalidad']
      }

      if(objetoDinamico?.estado_civil==undefined){
        return [false,'Falta identificar el estado civil del nuevo ministro','estado_civil']
      }

     if((objetoDinamico?.estado_civil?.id == 3 || objetoDinamico?.estado_civil?.id == 6) && (objetoDinamico?.conyuge==undefined || !objetoDinamico?.conyuge)){
        return [false,'Falta identificar el conyuge del nuevo ministro','conyuge']
     }

      
      return [true]
    }

    const validarCondicionesInicialesPaso1 = (validar)=>{

      const validaciones = [];
      
      if(objetoDinamico?.nombre==undefined || !objetoDinamico?.nombre){
        //return [false,'Falta identificar el nombre del nuevo ministro','nombre'];
        validaciones.push({texto:'Falta identificar el nombre del nuevo ministro',campo:'nombre'}) 
      }

      if(objetoDinamico?.apellido==undefined || !objetoDinamico?.apellido){
        //return [false,'Falta identificar el apellido del nuevo ministro','apellido'];
        validaciones.push({texto:'Falta identificar el apellido del nuevo ministro',campo:'apellido'}) 
      }

     if(objetoDinamico?.tipo_doc==undefined){
//        return [false,'Falta identificar el tipo de documento del nuevo ministro','tipo_documento']
        validaciones.push({texto:'Falta identificar el tipo de documento del nuevo ministro',campo:'tipo_documento'}) 
}

      if(objetoDinamico?.dni==undefined || !objetoDinamico?.dni){
//        return [false,'Falta identificar el número de documento del nuevo ministro','dni']
        validaciones.push({texto:'Falta identificar el número de documento del nuevo ministro',campo:'dni'}) 
      }

      if(objetoDinamico?.fecha_nacimiento==undefined || !objetoDinamico?.fecha_nacimiento){
//        return [false,'Falta identificar la fecha de nacimiento del nuevo ministro','fecha_nacimiento']
        validaciones.push({texto:'Falta identificar la fecha de nacimiento del nuevo ministro',campo:'fecha_nacimiento'}) 
      }else{
        
        if(!(fechaEsValida(objetoDinamico?.fecha_nacimiento))){
//          console.log('La fecha de naciminento no es válida')
//          return [false,'La fecha de naciminento no es válida','fecha_nacimiento']
          validaciones.push({texto:'La fecha de naciminento no es válida',campo:'fecha_nacimiento'}) 
        }
      }

      if(objetoDinamico?.nacionalidad==undefined){
//        return [false,'Falta identificar la nacionalidad del nuevo ministro','nacionalidad']
        validaciones.push({texto:'Falta identificar la nacionalidad del nuevo ministro',campo:'nacionalidad'}) 
      }

      if(objetoDinamico?.estado_civil==undefined){
//        return [false,'Falta identificar el estado civil del nuevo ministro','estado_civil']
        validaciones.push({texto:'Falta identificar el estado civil del nuevo ministro',campo:'estado_civil'}) 
      }

     if((objetoDinamico?.estado_civil?.id == 3 || objetoDinamico?.estado_civil?.id == 6) && (objetoDinamico?.conyuge==undefined || !objetoDinamico?.conyuge)){
//        return [false,'Falta identificar el conyuge del nuevo ministro','conyuge']
        validaciones.push({texto:'Falta identificar el cónyuge del nuevo ministro',campo:'conyuge'}) 
     }

     if(validar && validaciones.length>0){
          return [false,'Hay errores en los datos personales',validaciones]
     }

    return [true]
  }

    const validarCondicionesInicialesPaso2old = ()=>{


      if(objetoDinamico?.direccion==undefined || !objetoDinamico?.direccion){
        return [false,'Falta identificar el domicilio del nuevo ministro']
      }

      if(objetoDinamico?.localidad==undefined || !objetoDinamico?.localidad){
        return [false,'Falta identificar la localidad del nuevo ministro']
      }

      if(objetoDinamico?.cod_postal==undefined || !objetoDinamico?.cod_postal){
        return [false,'Falta identificar el código postal del nuevo ministro']
      }

      if(objetoDinamico?.provincia?.id==undefined){
        return [false,'Falta identificar la provincia del nuevo ministro']
      }

      if(objetoDinamico?.partido?.id==undefined){
        return [false,'Falta identificar el partido del nuevo ministro']
      }

      if(objetoDinamico?.id_region==undefined){
        return [false,'Falta identificar la región del nuevo ministro']
      }

      if(objetoDinamico?.distrito?.id==undefined){
        return [false,'Falta identificar el distrito del nuevo ministro']
      }
      return [true]
    }

    const validarCondicionesUbicacionIglesia = (validar)=>{

      const validaciones = [];

      if(objetoDinamico?.provinciaigl?.id==undefined){
        //return [false,'Falta identificar la provincia del nuevo ministro']
        validaciones.push({texto:'Falta identificar la provincia de la iglesia del nuevo ministro',campo:'provincia'})
      }

      if(objetoDinamico?.partidoigl?.id==undefined){
        //return [false,'Falta identificar el partido del nuevo ministro']
        validaciones.push({texto:'Falta identificar el partido de la iglesia del nuevo ministro',campo:'partido'})
      }

      if(objetoDinamico?.id_region==undefined){
        //return [false,'Falta identificar la región del nuevo ministro']
        validaciones.push({texto:'Falta identificar la región del nuevo ministro',campo:'region'})
      }

      if(objetoDinamico?.distrito?.id==undefined){
//        return [false,'Falta identificar el distrito del nuevo ministro']
        validaciones.push({texto:'Falta identificar el distrito del nuevo ministro',campo:'distrito'})
      }
    
      if(validar && validaciones.length>0){
        return [false,'Hay errores en los datos de ubicación de la iglesia',validaciones]
       }

      return [true]

    }

    const validarCondicionesInicialesPaso2 = (validar)=>{

      const validaciones = [];

      if(objetoDinamico?.direccion==undefined || !objetoDinamico?.direccion){
        //return [false,'Falta identificar el domicilio del nuevo ministro']
        validaciones.push({texto:'Falta identificar el domicilio del nuevo ministro',campo:'direccion'})
      }

      if(objetoDinamico?.localidad==undefined || !objetoDinamico?.localidad){
        //return [false,'Falta identificar la localidad del nuevo ministro']
        validaciones.push({texto:'Falta identificar la localidad del nuevo ministro',campo:'localidad'})
      }

      if(objetoDinamico?.cod_postal==undefined || !objetoDinamico?.cod_postal){
        //return [false,'Falta identificar el código postal del nuevo ministro']
        validaciones.push({texto:'Falta identificar el código postal del nuevo ministro',campo:'cod_postal'})
      }

      if(objetoDinamico?.provincia?.id==undefined){
        //return [false,'Falta identificar la provincia del nuevo ministro']
        validaciones.push({texto:'Falta identificar la provincia del nuevo ministro',campo:'provincia'})
      }

      if(objetoDinamico?.partido?.id==undefined){
        //return [false,'Falta identificar el partido del nuevo ministro']
        validaciones.push({texto:'Falta identificar el partido del nuevo ministro',campo:'partido'})
      }

      if(objetoDinamico?.id_region==undefined){
        //return [false,'Falta identificar la región del nuevo ministro']
        validaciones.push({texto:'Falta identificar la región del nuevo ministro',campo:'region'})
      }

      if(objetoDinamico?.distrito?.id==undefined){
//        return [false,'Falta identificar el distrito del nuevo ministro']
        validaciones.push({texto:'Falta identificar el distrito del nuevo ministro',campo:'distrito'})
      }
    
      if(validar && validaciones.length>0){
        return [false,'Hay errores en los datos de ubicación',validaciones]
       }

      return [true]

    }

    const validarCondicionesInicialesPaso3old = ()=>{

      if(objetoDinamico?.telefono==undefined || !objetoDinamico?.telefono){
        return [false,'Falta identificar el teléfono del nuevo ministro']
      }

      if(!isValidPhoneNumber(objetoDinamico?.telefono)){
        return [false,'El número de teléfono parece ser inválido. Incluya "Código de área local" y "Número de teléfono local". Por ejemplo: 11 1234-5678']
      }

      if(objetoDinamico?.celular==undefined || !objetoDinamico?.celular){
        return [false,'Falta identificar el celular del nuevo ministro']
      }

      if(!isValidPhoneNumber(objetoDinamico?.celular)){
        return [false,'El número de celular parece ser inválido. Incluya "Código de área local" y "Número de teléfono local". Por ejemplo: 11 1234-5678']
      }

      if(numerosIguales(objetoDinamico?.telefono,objetoDinamico?.celular)){
        return [false,'Los números de teléfono y celular no pueden ser iguales']
      }

      if(objetoDinamico?.email==undefined || !objetoDinamico?.email){
        return [false,'Falta identificar el email del nuevo ministro']
      }

      if(!regex.EMAIL.test(objetoDinamico?.email)){
        return [false,'El email es inválido']
      }

      return [true]
    }
    
    const validarCondicionesInicialesPaso3 = (validar)=>{

    const validaciones = [];

      if(objetoDinamico?.telefono==undefined || !objetoDinamico?.telefono){
        //return [false,'Falta identificar el teléfono del nuevo ministro']
        validaciones.push({texto:'El teléfono es obligatorio',campo:'telefono'})
      }

      if(!isValidPhoneNumber(objetoDinamico?.telefono || '')){
        //return [false,'El número de teléfono parece ser inválido. Incluya "Código de área local" y "Número de teléfono local". Por ejemplo: 11 1234-5678']
        validaciones.push({texto:'El número de teléfono parece ser inválido. Incluya "Código de área local" y "Número de teléfono local". Por ejemplo: 11 1234-5678',campo:'telefono'})
      }


      if(objetoDinamico?.celular==undefined || !objetoDinamico?.celular){
        //return [false,'Falta identificar el celular del nuevo ministro']
        validaciones.push({texto:'El celular es obligatorio',campo:'celular'})
      }


      if(!isValidPhoneNumber(objetoDinamico?.celular || '')){
        //return [false,'El número de celular parece ser inválido. Incluya "Código de área local" y "Número de teléfono local". Por ejemplo: 11 1234-5678']
        validaciones.push({texto:'El número de celular parece ser inválido. Incluya "Código de área local" y "Número de teléfono local". Por ejemplo: 11 1234-5678',campo:'celular'})
      }

      if(numerosIguales(objetoDinamico?.telefono,objetoDinamico?.celular)){
        //return [false,'Los números de teléfono y celular no pueden ser iguales']
        validaciones.push({texto:'Los números de teléfono y celular no pueden ser iguales',campo:'celular'})
      }

      if(objetoDinamico?.email==undefined || !objetoDinamico?.email){
        //return [false,'Falta identificar el email del nuevo ministro']
        validaciones.push({texto:'El e-mail es obligatorio',campo:'email'})
      }

      if(!regex.EMAIL.test(objetoDinamico?.email)){
        //return [false,'El email es inválido']
        validaciones.push({texto:'El email es inválido',campo:'email'})
      }

      if(validar && validaciones.length>0){
        return [false,'Hay errores en los datos de contacto',validaciones]
       }

      return [true]
    }

    const validarCondicionesInicialesPaso4 = (validar)=>{

      const validaciones = [];
      /*
      if(objetoDinamico?.fecha_conversion==undefined || !objetoDinamico?.fecha_conversion){
        //return [false,'Falta identificar la fecha de conversión']
        validaciones.push({texto:'La fecha de conversión es obligatorio',campo:'fecha_conversion'})
      }else{
        if(!(fechaEsValida(objetoDinamico?.fecha_conversion))){
          //return [false,'La fecha de conversión no es válida']
          validaciones.push({texto:'La fecha de conversión no es válida',campo:'fecha_conversion'})
        }
      }

      if(objetoDinamico?.fecha_bautismo_a==undefined || !objetoDinamico?.fecha_bautismo_a){
        //return [false,'Falta identificar la fecha de bautismo en aguas']
        validaciones.push({texto:'Fecha de bautismo en aguas es obligatorio',campo:'fecha_bautismo_a'})
      }else{
        if(!(fechaEsValida(objetoDinamico?.fecha_bautismo_a))){
          //return [false,'La fecha de bautismo en agua no es válida']
          validaciones.push({texto:'La fecha de bautismo en agua no es válida',campo:'fecha_bautismo_a'})
        }
      }
      */
      if(objetoDinamico?.year_conversion==undefined || !objetoDinamico?.year_conversion){
        //return [false,'Falta identificar la fecha de bautismo en aguas']
        validaciones.push({texto:'Año de conversión es obligatorio',campo:'year_conversion'})
      }else{

        const year_actual = new Date().getFullYear();

        if((Number(objetoDinamico?.year_conversion) > year_actual) || (Number(objetoDinamico?.year_conversion) < year_actual-100)){
          validaciones.push({texto:`El año de conversion debe ser igual o menor al año actual y mayor a ${year_actual-100} `,campo:'year_conversion'})
        }
      }

      if(objetoDinamico?.year_bautismo==undefined || !objetoDinamico?.year_bautismo){
        //return [false,'Falta identificar la fecha de bautismo en aguas']
        validaciones.push({texto:'Año de bautismo en agua es obligatorio',campo:'year_bautismo'})
      }else{

        const year_actual = new Date().getFullYear();

        if((Number(objetoDinamico?.year_bautismo) > year_actual) || (Number(objetoDinamico?.year_bautismo) < year_actual-100)){
          validaciones.push({texto:`El año de bautismo en agua debe ser igual o menor al año actual y mayor a ${year_actual-100} `,campo:'year_bautismo'})
        }
      }

      if(objetoDinamico?.bautismo_es==undefined){
//        return [false,'Falta responder sobre el bautismo en el Espíritu Santo']
        validaciones.push({texto:'Falta responder sobre el bautismo en el Espíritu Santo',campo:'bautismo_es'})
      }

      /*if(objetoDinamico?.bautismo_es=='SI'){
        if(objetoDinamico?.fecha_bautismo_es==undefined || !objetoDinamico?.fecha_bautismo_es){
          //return [false,'Falta identificar la fecha de bautismo en el Espíritu Santo']
          validaciones.push({texto:'Falta identificar la fecha de bautismo en el Espíritu Santo',campo:'fecha_bautismo_es'})
        }else{
          if(!(fechaEsValida(objetoDinamico?.fecha_bautismo_es))){
            //return [false,'La fecha de bautismo en el Espíritu Santo no es válida']
            validaciones.push({texto:'La fecha de bautismo en el Espíritu Santo no es válida',campo:'fecha_bautismo_es'})
          }
        }
      }*/

      if(validar && validaciones.length>0){
        return [false,'Hay errores en los datos de conversión y bautismo',validaciones]
       }

      return [true]
    
    }

    const validarCondicionesInicialesPaso5old = ()=>{

      if(objetoDinamico?.sostenimiento==undefined || !objetoDinamico?.sostenimiento){
        return [false,'Falta responder sobre el sostenimiento financiero']
      }

      if(typeof objetoDinamico?.sostenimiento == 'string' && objetoDinamico?.sostenimiento.length<20){
        return [false,'Responda como mínimo con 20 caracteres sobre el sostenimiento financiero']
      }

      if(objetoDinamico?.diezmo==undefined){
        return [false,'Falta responder sobre el diezmo']
      }

      return [true]
    }
    const validarCondicionesInicialesPaso5 = (validar)=>{

      const validaciones = [];

      if(objetoDinamico?.sostenimiento==undefined || !objetoDinamico?.sostenimiento){
        //return [false,'Falta responder sobre el sostenimiento financiero']
        validaciones.push({texto:'El sostenimiento financiero es obligatorio',campo:'sostenimiento'})
      }

      if(typeof objetoDinamico?.sostenimiento == 'string' && objetoDinamico?.sostenimiento.length<20){
        //return [false,'Responda como mínimo con 20 caracteres sobre el sostenimiento financiero']
        validaciones.push({texto:'Responda como mínimo con 20 caracteres sobre el sostenimiento financiero',campo:'sostenimiento'})
      }

      if(objetoDinamico?.diezmo==undefined){
        //return [false,'Falta responder sobre el diezmo']
        validaciones.push({texto:'Falta responder sobre el diezmo',campo:'diezmo'})
      }

      if(validar && validaciones.length>0){
        return [false,'Hay errores en los datos de finanzas y diezmo',validaciones]
       }

      return [true]

    }

    const validarCondicionesInicialesPaso6old = ()=>{

      if(objetoDinamico?.pastores==undefined || !objetoDinamico?.pastores){
        return [false,'Falta ingresar el nombre de los pastores con quienes ha estado trabajando']
      }

      if(objetoDinamico?.pastores instanceof Array){
        if(objetoDinamico?.pastores.length<1){
          return [false,'Falta ingresar el nombre al menos un pastor con quien ha estado trabajando']
        }
      }

     
      return [true]
    }

    const validarCondicionesInicialesPaso6 = (validar)=>{

      const validaciones = [];

      if(objetoDinamico?.pastores==undefined || !objetoDinamico?.pastores){
        //return [false,'Falta ingresar el nombre de los pastores con quienes ha estado trabajando']
        validaciones.push({texto:'Falta ingresar el nombre de los pastores con quienes ha estado trabajando',campo:'pastores'})
      }

      if(objetoDinamico?.pastores instanceof Array){
        if(objetoDinamico?.pastores.length<1){
          //return [false,'Falta ingresar el nombre al menos un pastor con quien ha estado trabajando']
          validaciones.push({texto:'Falta ingresar el nombre al menos un pastor con quien ha estado trabajando',campo:'pastores'})
        }
      }

     
      if(validar && validaciones.length>0){
        return [false,'Hay errores en los datos de pastores',validaciones]
       }

      return [true]
    }

    const validarCondicionesInicialesPaso7 = (validar)=>{

      const validaciones = [];

      if(objetoDinamico?.nombrepastor==undefined || !objetoDinamico?.nombrepastor){
        //return [false,'Falta identificar el nombre del pastor']
        validaciones.push({texto:'Falta identificar el nombre del pastor',campo:'nombrepastor'})
      }

      if(objetoDinamico?.emailpastor==undefined || !objetoDinamico?.emailpastor){
        //return [false,'Falta identificar el email del pastor']
        validaciones.push({texto:'Falta identificar el email del pastor',campo:'emailpastor'})
      }

      if(!regex.EMAIL.test(objetoDinamico?.emailpastor)){
        //return [false,'El email del pastor es inválido']
        validaciones.push({texto:'El email del pastor es inválido',campo:'emailpastor'})
      }

      if(objetoDinamico?.nombreanciano==undefined || !objetoDinamico?.nombreanciano){
        //return [false,'Falta identificar el nombre del anciano/diácono']
        validaciones.push({texto:'Falta identificar el nombre del anciano/diácono',campo:'nombreanciano'})
      }

      if(objetoDinamico?.emailanciano==undefined || !objetoDinamico?.emailanciano){
        //return [false,'Falta identificar el email del anciano/diácono']
        validaciones.push({texto:'Falta identificar el email del anciano/diácono',campo:'emailanciano'})
      }

      if(!regex.EMAIL.test(objetoDinamico?.emailanciano)){
        //return [false,'El email del anciano/diácono es inválido']
        validaciones.push({texto:'El email del anciano/diácono es inválido',campo:'emailanciano'})
      }

      if((objetoDinamico?.estado_civil?.id == 3 || objetoDinamico?.estado_civil?.id == 6)){
          if(objetoDinamico?.conyuge==undefined || !objetoDinamico?.conyuge){
            validaciones.push({texto:'Falta identificar el nombre del cónyuge',campo:'nombreconyuge'})
          }

          if(objetoDinamico?.emailconyuge==undefined || !objetoDinamico?.emailconyuge){
            //return [false,'Falta identificar el email del conyuge']
            validaciones.push({texto:'Falta identificar el email del conyuge',campo:'emailconyuge'})
          }

          if(!regex.EMAIL.test(objetoDinamico?.emailconyuge)){
            //return [false,'El email del cónyuge es inválido']
            validaciones.push({texto:'El email del cónyuge es inválido',campo:'emailconyuge'})
          }
      }
      
      if(validar && validaciones.length>0){
        return [false,'Hay errores en los datos de referencias',validaciones]
       }

      return [true]

    }
    const iniciarCancelar_paso1 = ()=>{
        setObjetoDinamico({...objetoDinamico,
                          nombre:'',apellido:'',fecha_nacimiento:'',tipo_doc:null,dni:'',nacionalidad:{id:3,nombre:'Argentina'},estado_civil:null,conyuge:''})
       // set_paso_datos_personales_ok(false)
       setContinuar(false)
    }

    const iniciarCancelar_paso6 = ()=>{
      setObjetoDinamico({...objetoDinamico,
                        pastores:[]})
     // set_paso_datos_personales_ok(false)
     set_paso_cuestionario2_ok(false)
  }

  const iniciarCancelar_paso7 = ()=>{
    setObjetoDinamico({...objetoDinamico,
                        emailpastor:'',
                        nombrepastor:'',
                        nombreanciano:'',
                        emailanciano:'',
                        nombreconyuge:'',
                        emailconyuge:''
                    })
   // set_paso_datos_personales_ok(false)
   set_paso_referencias_ok(false)
}

    const iniciarCancelar_paso2 = ()=>{
      setObjetoDinamico({...objetoDinamico,id_region:null,partido:null,provincia:null,id_distrito:null,
                        localidad:'',cod_postal:'',barrio:'',direccion:''})
      set_paso_datos_personales_ok(false)
//      set_paso_ubicacion_inicial_ok(false)
    }

    const iniciarCancelar_paso3 = ()=>{
      setObjetoDinamico({...objetoDinamico,
                        telefono:'',celular:'',email:''})
//      set_paso_contacto_ok(false)
      set_paso_ubicacion_inicial_ok(false)
    }

    const iniciarCancelar_paso4 = ()=>{
      setObjetoDinamico({...objetoDinamico,
                        bautismo_es:false,
                        fecha_conversion:null,fecha_bautismo_a:null,fecha_bautismo_es:null})
//      set_paso_contacto_ok(false)
      set_paso_contacto_ok(false)
    }

    const iniciarCancelar_paso5 = ()=>{
      setObjetoDinamico({...objetoDinamico,
                        diezmo:false,
                        sostenimiento:null})
//      set_paso_contacto_ok(false)
      set_paso_cuestionario_ok(false)
    }

    const iniciarContinuar_paso1 = ()=>{
          
      if(!validarCondicionesInicialesPaso1()[0]){
        alert(validarCondicionesInicialesPaso1()[1])
        return
      }
      set_paso_datos_personales_ok(true)
    }

    const iniciarContinuar_paso2 = ()=>{
          
      if(!validarCondicionesInicialesPaso2()[0]){
        alert(validarCondicionesInicialesPaso2()[1])
        return
      }
      set_paso_ubicacion_inicial_ok(true)
    }


    const iniciarContinuar_paso3 = ()=>{
      if(!validarCondicionesInicialesPaso3()[0]){
        alert(validarCondicionesInicialesPaso3()[1])
        return
      }
      set_paso_contacto_ok(true)
    }

    const iniciarContinuar_paso4 = ()=>{
      if(!validarCondicionesInicialesPaso4()[0]){
        alert(validarCondicionesInicialesPaso4()[1])
        return
      }
      set_paso_cuestionario_ok(true)
    }

    const iniciarContinuar_paso5 = ()=>{
      if(!validarCondicionesInicialesPaso5()[0]){
        alert(validarCondicionesInicialesPaso5()[1])
        return
      }
      set_paso_cuestionario2_ok(true)
    }

    const iniciarContinuar_paso6 = ()=>{
      if(!validarCondicionesInicialesPaso6()[0]){
        alert(validarCondicionesInicialesPaso6()[1])
        return
      }
      set_paso_pastores_ok(true)
    }

    const iniciarContinuar_paso7 = ()=>{
      if(!validarCondicionesInicialesPaso7()[0]){
        alert(validarCondicionesInicialesPaso7()[1])
        return
      }
      set_paso_referencias_ok(true)
    }


    const callbackValidate = async (values)=>{
/*
      if(!campoAdicional)
      {
        return [false,'Mensaje']
      }

      const {data} = await consulteAlgoAlBackend()

      let validado = true;

      const campox = data.campox;

      if(!campox){
        validado = false
      }

      if(!validado){
          return [false,`Verifique...${JSON.stringify(values)}`]
      }
*/

      /*
      // Validación de datos a enviar a la mutacion + values del formik
      const validacionEncargado = validarEncargado(encargado);

      if(validacionEncargado[0]==false){
          return[false,validacionEncargado[1]]
      }

      */

      const validarKey = await keyNoValida(datosPatrocinante._id);

      console.log(validarKey)
      if(!validarKey[0]){
        return [false,validarKey[1]]
      }

      const validardni = await dniEstaEnUso(objetoDinamico.dni);

      console.log(validardni)
      if(!validardni[0]){
        return [false,validardni[1]]
      }

      if(!analiticoFile)
      {
        return [false,'Falta adjuntar el archivo de analítico']
      }

      if(!dniFrenteFile)
      {
        return [false,'Falta adjuntar el archivo del DNI (Frente)']
      }

      if(!dniReversoFile)
      {
        return [false,'Falta adjuntar el archivo del DNI (Reverso)']
      }

      if(!foto4x4File)
      {
        return [false,'Falta adjuntar la foto 4 x 4']
      }

      if(!antecedentes_penFile)
      {
        return [false,'Falta adjuntar el certificado de antecedentes penales']
      }

      if(objetoDinamico.estado_civil.id==3 || objetoDinamico.estado_civil.id==6 ){
        if(!certificadoMatrimonioFile)
        {
          return [false,'Falta adjuntar el certificado de matrimonio']
        }
      }

      if(objetoDinamico.compromiso!=true)
      {
        return [false,'Debe aceptar el documento de compromiso ministerial UAD']
      }

      if(objetoDinamico.doctrina!=true)
      {
        return [false,'Debe aceptar el documento de doctrinas fundamentales UAD']
      }

      if(objetoDinamico.estatutos!=true)
      {
        return [false,'Debe aceptar el documento de estatutos y testimonios UAD']
      }

      setvalidarCampos(true)
      let validacion_parcial;

      validacion_parcial = validarCondicionesInicialesPaso1(true);

      if(validacion_parcial[0]==false){
          return[false,validacion_parcial[1]]
      }

      validacion_parcial = validarCondicionesInicialesPaso2(true);

      if(validacion_parcial[0]==false){
        return[false,validacion_parcial[1]]
      }

      validacion_parcial = validarCondicionesInicialesPaso3(true);

      if(validacion_parcial[0]==false){
        return[false,validacion_parcial[1]]
      }
   
      validacion_parcial = validarCondicionesInicialesPaso4(true);

      if(validacion_parcial[0]==false){
        return[false,validacion_parcial[1]]
      }

      validacion_parcial = validarCondicionesInicialesPaso5(true);

      if(validacion_parcial[0]==false){
        return[false,validacion_parcial[1]]
      }

      validacion_parcial = validarCondicionesInicialesPaso6(true);

      if(validacion_parcial[0]==false){
        return[false,validacion_parcial[1]]
      }

      validacion_parcial = validarCondicionesInicialesPaso7(true);

      if(validacion_parcial[0]==false){
        return[false,validacion_parcial[1]]
      }

      validacion_parcial = validarCondicionesUbicacionIglesia(true);

      if(validacion_parcial[0]==false){
        return[false,validacion_parcial[1]]
      }

      if(objetoDinamico.id_tramite_patrocinio==undefined || objetoDinamico.id_tramite_patrocinio==''){
        return[false,'No se pudo identificar el id del trámite de patrocinio']
      }

      return [true]

    }

    const handleCheck = (e)=>{
       
      if(e.target.checked){
          setObjetoDinamico({...objetoDinamico,[e.target.name]:true})
      }else{
        setObjetoDinamico({...objetoDinamico,[e.target.name]:false})
      }
  }

  const handleAceptacion = (campo,valor)=>{
       
    if(valor){
        setObjetoDinamico({...objetoDinamico,[campo]:true})
    }else{
      setObjetoDinamico({...objetoDinamico,[campo]:false})
    }
}

    // esto es para ver los datos del usuario
    /*return <Box>
          {Object.entries(usuario).map(item=>{
              return <p>{item[0]} ---- {item[1]}</p>
          })}
    </Box>
    */

    /*
    FUE NECESARIO agregar un flag para que luego de seleccionar el ministroSolicitud
    no renderice inmediatamente el componente FormularioTramite.
    Sucedía que apenas se seleccionaba un ministroSolicitud se renderizadaba el formik
    antes de que se actualice el objeto objetoDinamico con la información dinámica que se necesita
    del contexto para poder cargar correctamente los selects de status, region, distrito y ministro.
    Se usa un flag de "Continuar" para que renderice algo antes y de tiempo a que se ejecuten los
    useEffects de éste componente y se actualice el objetoDinmico antes de renderizar el formik.
    El flujo es, primero se renderiza, luego se ejecutan los useEffects entonces hay situaciones en que
    hay que implementar lógica para que cada acción sea en el momento adecuado.
    En este caso 1) seleccionar un ministroSolicitud, luego actualizar objetiDinamico luego renderizar
    el formulario formik con el contexto dinámico correcto.
    Sucedía que primero montaba el formulario, renderizaba el mismo, ejecutaba un useEffect del formik
    y luego procesaba los useEffects del componente InicioTramite y quedaba mal sincronizado el proceso.
    Pensaba que era un problema de closures con las funciones allowedValues de campos_formuularios
    pero no era un closure sino un problema de renderizado antes de tiempo del componente.
    Se resolvió con un flag y un componente de confirmación previo.
    */

    const CustomSubmit = ({submit,values})=>{
      const [esperarRespuesta,setEsperarRespuesta] = React.useState(false)
      const [codigo,setCodigo] = React.useState('')
      const [envios,setEnvios] = React.useState(0)
      const [identificador,setIdentificador] = React.useState('')
      const [enviando,setEnviando] = React.useState(false)

      const validarpaso = ()=>{
              callbackValidate(values)
              .then(resultado=>{
                      if (resultado[0]==false){
                          snackbar.showMessage(resultado[1],'error')
                          //alert(resultado[1],'error')
                          return 
                      }else{
                          //submit()
                          setEsperarRespuesta(true)
                      }
              })
  
      }
  
      const handlechange =(e)=>{
         if(regex.NUMERIC.test(e.target.value)){
            setCodigo(e.target.value)
         }
      }
  
      const verificarCodigo = async ()=>{
  
        try{
          const verificacion = await Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/validarcodigo/${identificador}/${codigo}`)
          if(verificacion.data.resultado==true){
            Swal.fire({
              text:`El código es correcto`,
              icon: 'success',
              showCancelButton:true,
              confirmButtonText:'Enviar solicitud',
              cancelButtonText:'Cancelar'
          }).then(
              resultado=>{
                  if (resultado.value){
                      submit();
                  }else{
                      console.log("Se canceló la operación de la solicitud")
                  }
              }
          )
          }else{
            Swal.fire(
              'El código no es correcto',
              'Verifique el código o vuelva a enviarlo',
              'error'
            )
          }
  
        }catch(err){
            console.log(err)
            Swal.fire(`No se pudo verificar el código`,err?.response?.data?.message || '','error')
        }
  }
  
      const enviarCodigo = async ()=>{
        const codigo = Math.random().toString().toUpperCase().slice(2, 8)
        const identificador = uuidv4();
  
        setIdentificador(identificador);
  
        try{
            setEnviando(true)
            const envio = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/codigoverificador/movil/${identificador}`,{nombre:objetoDinamico.nombre,numero:objetoDinamico.celular})
            setEnvios(envios+1)
            //Swal.fire(`Enviamos un \nmensaje con un código de 6 dígitos al e-mail: ${objetoDinamico.email}`,'Recuerde revisar también la carpeta "Correo no deseado"','info')
            setCodigo('')
            Swal.fire(`El mensaje fue enviado a ${objetoDinamico.celular}`,'Enviamos un código de 6 dígitos por SMS a su celular','info')
            .then(()=>{
              setTimeout(() => {
                  hacerFoco('verif-cod')
              }, 1000);
                
            })

            setEnviando(false)
          }catch(err){
            console.log(err)
            setEnviando(false)
            Swal.fire(`No se pudo enviar el código`,err?.response?.data?.message || '','error')
        }
      }
  
      return  <Box>
              <Button onClick={validarpaso} style={{background:'steelblue'}} variant="outlined">
                  Continuar
              </Button>
              <AlertDialog
                  titulo="Validación del número de celular"
                  open={esperarRespuesta}
                  procesarCancelar={()=>setEsperarRespuesta(false)}
                  //procesarResultado={()=>alert('Procesando')}
              >
                  <Box>
                      <p> Antes de enviar la solicitud necesitamos validar su número de celular</p>
                      <br />
                      <p>Vamos a enviar un mensaje a <b>{objetoDinamico.celular}</b> con un código de 6 dígitos</p>
                      <br />
                      <p>Por favor haga clic en el botón "ENVIAR CÓDIGO" y luego ingrese el código recibido</p>
                      
                      <Box style={{marginTop:'20px', marginBottom:'20px',flexDirection:'column',display:'flex',justifyContent:'center'}}>
                          <Button onClick={enviarCodigo} variant="outlined" color="secondary">
                                {envios==0 ? 'Enviar código' : 'Reenviar código'}
                          </Button>
                          {enviando && <LoaderCircular/>}
                      </Box>
  
                      <Box style={{marginTop:'20px', marginBottom:'20px',display:'flex',justifyContent:'space-evenly'}}>
                          <input id="verif-cod" maxLength={6} value={codigo} placeholder='Ingrese el código aquí' onChange={handlechange} style={{border:'solid 1px red',padding:'10px'}} type="text"/>
                          {<Button onClick={verificarCodigo} style={{background:'steelblue'}} variant="outlined">
                                Verificar código
                          </Button>}
                      </Box>
  
                  </Box>
              </AlertDialog>
      </Box> 
     
    }

    if(continuar==false){
        return <Box className='form-body-tram' style={{margin:'25px'}}>
            <Typography style={{marginTop:'25px',maxWidth:'640px',marginRight:'auto',marginLeft:'auto'}} variant="h5">Para continuar con el proceso de solicitud de credenciales por favor lea atentamente las instrucciones siguientes:</Typography>
            <Box sx={{padding:'10px 80px 10px 80px',textAlign:'left',background:''}} id="requisitos1racred">
              <h4>1 - Verifique la información que <b>{datosPatrocinante?.id_tramite?.ministro?.nombre || 'su patrocinante'}</b> ha brindado acerca de la iglesia a la cual usted pertenece.</h4>
                <div>{/* Quitamos nombre y apellido porque ahora lo va a poder editar libremente, email porque ya fue validado antes, sexo es improbable que deba modificarlo */}
                  <h4>Usted pertenece a la iglesia:</h4>
                    <ul className='list-req-1cr'>
                        <li>{datosPatrocinante?.iglesia.nombre}</li>
                    </ul>
                    <h4>Su iglesia se encuentra ubicada en:</h4>
                    <ul className='list-req-1cr'>
                        <li>Provincia : {datosPatrocinante?.provincia.nombre}</li>
                        <li>Partido : {datosPatrocinante?.partido.nombre}</li>
                    </ul>
                </div>
              {/*<>
              <h4>1 - Verifique la información que <b>{datosPatrocinante?.id_tramite?.ministro?.nombre || 'su patrocinante'}</b> ha brindado acerca de su persona.</h4>
                <div>
                    <h4>Sus datos personales son:</h4>
                    <ul className='list-req-1cr'>
                        <li>Nombre/s : {datosPatrocinante?.nombre}</li>
                        <li>Apellido/s : {datosPatrocinante?.apellido}</li>
                        <li>Sexo : {datosPatrocinante?.sexo=='F' ? 'Mujer' : 'Hombre'}</li>
                        <li>E-mail : {datosPatrocinante?.email}</li>
                    </ul>
                    <h4>La iglesia a la cuál usted pertenece se encuentra ubicada en:</h4>
                    <ul className='list-req-1cr'>
                        <li>Provincia : {datosPatrocinante?.provincia.nombre}</li>
                        <li>Partido : {datosPatrocinante?.partido.nombre}</li>
                    </ul>
                </div>
                </>*/}
                <br />
                <p className='color-red'>En caso de que alguno de estos datos no sea correcto por favor solicite a {datosPatrocinante?.id_tramite?.ministro?.nombre} que corrobore la información en cuestión</p>
                <h4>2 - Lea los <a target="_blank" className="" href='https://uadspace.nyc3.cdn.digitaloceanspaces.com/public_images/cre02_requisitos_estudios_teologicos.pdf'>requisitos de estudios teológicos</a> que debe cumplir para poder recibir credenciales en la UAD</h4>

                <h4>3 - Asegúrese de contar con los elementos que se detallan a continuación los cuales deberán ser suministrados como archivos adjuntos en la siguiente sección:</h4>
                <div>
                    <ul className='list-req-1cr'>
                        <li>Certificado de estudios teológicos en formato PDF</li>
                        <li>Foto de su DNI (Frente) en formato jpg,jpeg o npg</li>
                        <li>Foto de su DNI (Reverso) en formato jpg,jpeg o npg</li>
                        <li>Foto 4 x 4 en formato jpg,jpeg o npg</li>
                        <li>Certificado de antecedentes penales en formato PDF</li>
                        <li>Certificado de matrimonio si es casado/a en formato PDF</li>
                    </ul>
                </div>
                <h4>4 - Lea atentamente los documentos que se detallan a continuación sobre los cuales le pediremos su aceptación y conformidad en la siguiente sección:</h4>
                <div>
                    {/*<ul className='list-req-1cr'>
                        <li><a target="_blank" className="ml-2 text-large color-black" href='https://uadspace.nyc3.cdn.digitaloceanspaces.com/public_images/estatutos_nacionales_uad.pdf'>Testimonio y Estatutos de la Unión de las Asambleas de Dios</a></li>
                        <li><a target="_blank" className="ml-2 text-large color-black" href='https://uadspace.nyc3.cdn.digitaloceanspaces.com/public_images/cre01_doctrina_uad.pdf'>Doctrinas Fundamentales de la Unión de las Asambleas de Dios</a></li>
                        <li><a target="_blank" className="ml-2 text-large color-black" href='https://uadspace.nyc3.cdn.digitaloceanspaces.com/public_images/cre10_compromiso_ministerial.pdf'>Compromiso Ministerial de la Unión de las Asambleas de Dios</a></li>
                    </ul>*/}
                    <ul className='list-req-1cr'>
                        <li className='url'>
                            <Box sx={{display:'flex',alignItems:'center'}}>
                                <span title='Visualice el documento de Estatutos y Testimonio UAD' onClick={()=>setDocumento('estatutos')}>Testimonio y Estatutos de la Unión de las Asambleas de Dios</span>
                                <BrowserUpdatedIcon className='ml-2'/>
                            </Box>
                        </li>
                        <li className='url'>
                            <Box sx={{display:'flex',alignItems:'center'}}>
                            <span title='Visualice el documento de Doctrinas Fundamentales UAD' onClick={()=>setDocumento('doctrina')}>Doctrinas Fundamentales de la Unión de las Asambleas de Dios</span>                                <BrowserUpdatedIcon className='ml-2'/>
                            </Box>
                        </li>
                        <li className='url'>
                            <Box sx={{display:'flex',alignItems:'center'}}>
                            <span title='Visualice el documento de Compromiso Ministerial UAD' onClick={()=>setDocumento('compromiso')}>Compromiso Ministerial de la Unión de las Asambleas de Dios</span>                                <BrowserUpdatedIcon className='ml-2'/>
                            </Box>
                        </li>
                    </ul>
                </div>
                <h4>5 - Disponga de la información que le pediremos sobre dos o tres personas que puedan dar referencias de su ministerio y testimonio: </h4>
                <div>
                    <ul className='list-req-1cr'>
                        <li>Nombre / apellido / e-mail de su pastor</li>
                        <li>Nombre / apellido / e-mail de un anciano o diácono de su iglesia</li>
                        <li>Nombre / apellido / e-mail de su cónyuge</li>
                    </ul>
                </div>
                <h4>6 - Una vez que considere que cumple con las condiciones detalladas en los pasos anteriores haga clic en el botón "Continuar" y complete el formulario que encontrará a continuación</h4>
            </Box>
            <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                <Button variant='outlined' color='primary' onClick={iniciarContinuar}>Continuar</Button>
                <Button style={{background:'tomato'}} variant="outlined" onClick={cancelar}>
                      cancelar
                </Button>
            </Box>
            <AlertDialog open={documento} 
                    titulo={'Vista de lectura e impresión del documento'} 
                    //style={{background:'rgb(96 147 179)'}}
                    //style2={{background:'rgb(186 203 213)'}}
                    style={{background:'white'}}
                    style2={{background:'white'}}
                    fullWidth={true}
                    maxWidth={'md'}
                    procesarCancelar = {()=>setDocumento(null)}
                   // procesarResultado={()=>setTramiteSeleccionado(null)}
                    error={''}>
                          <Aceptaciones1raCredencial tipo={documento} 
                                                      objLecturas={{lecturas,setLecturas}} 
                                                      instancia='lectura'/>
            </AlertDialog>
        </Box>
    }

    if (!paso_datos_personales_ok && false){
        return <>
            <DatosPersonales 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  nacionalidades={nacionalidades}
                  estadosCiviles={estadosCiviles}
                  tiposDoc={tiposDoc}
                  paso_cumplido={paso_datos_personales_ok}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
              <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                {validarCondicionesInicialesPaso1()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso1}>Continuar</Button>}
                {!validarCondicionesInicialesPaso1()[0] && <p style={{color:'red'}}>{validarCondicionesInicialesPaso1()[1]}</p>}
                <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso1}>
                      cancelar
                </Button>
            </Box>
        </>
    }

    if (!paso_ubicacion_inicial_ok && false){
      return <>
           <UbicacionInicial 
                usuario={usuario} 
                finalizar={finalizar} 
                provincias={provincias}
                partidos={partidos}
                paso_cumplido={paso_ubicacion_inicial_ok}
                objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
            />
            <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
              {validarCondicionesInicialesPaso2()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso2}>Continuar</Button>}
              {!validarCondicionesInicialesPaso2()[0] && <p style={{color:'red'}}>{validarCondicionesInicialesPaso2()[1]}</p>}
              <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso2}>
                    cancelar
              </Button>
          </Box>
      </>
   }

   if (!paso_contacto_ok && false){
      return <>
          <Contacto 
                usuario={usuario} 
                finalizar={finalizar} 
                paso_cumplido={paso_contacto_ok}
                objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
            />
            <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
              {validarCondicionesInicialesPaso3()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso3}>Continuar</Button>}
              {!validarCondicionesInicialesPaso3()[0] && <p style={{color:'red'}}>{validarCondicionesInicialesPaso3()[1]}</p>}
              <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso3}>
                    cancelar
              </Button>
          </Box>
      </>
  }

  if (!paso_cuestionario_ok && false){
    return <>
        <Cuestionario 
              usuario={usuario} 
              finalizar={finalizar} 
              paso_cumplido={paso_cuestionario_ok}
              objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
          />
          <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
            {validarCondicionesInicialesPaso4()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso4}>Continuar</Button>}
            {!validarCondicionesInicialesPaso4()[0] && <p style={{color:'red'}}>{validarCondicionesInicialesPaso4()[1]}</p>}
            <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso4}>
                  cancelar
            </Button>
        </Box>
    </>
}

if (!paso_cuestionario2_ok && false){
  return <>
      <Cuestionario2 
            usuario={usuario} 
            finalizar={finalizar} 
            paso_cumplido={paso_cuestionario2_ok}
            objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
        />
        <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
          {validarCondicionesInicialesPaso5()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso5}>Continuar</Button>}
          {!validarCondicionesInicialesPaso5()[0] && <p style={{color:'red'}}>{validarCondicionesInicialesPaso5()[1]}</p>}
          <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso5}>
                cancelar
          </Button>
      </Box>
  </>
}

if (!paso_pastores_ok && false){
  return <>
      <Pastores 
            usuario={usuario} 
            finalizar={finalizar} 
            paso_cumplido={paso_pastores_ok}
            objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
        />
        <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
          {validarCondicionesInicialesPaso6()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso6}>Continuar</Button>}
          {!validarCondicionesInicialesPaso6()[0] && <p style={{color:'red'}}>{validarCondicionesInicialesPaso6()[1]}</p>}
          <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso6}>
                cancelar
          </Button>
      </Box>
  </>
}

if (!paso_referencias_ok && false){
  return <>
      <Referencias 
            usuario={usuario} 
            finalizar={finalizar} 
            paso_cumplido={paso_referencias_ok}
            objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
        />
        <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
          {validarCondicionesInicialesPaso7()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso7}>Continuar</Button>}
          {!validarCondicionesInicialesPaso7()[0] && <p style={{color:'red'}}>{validarCondicionesInicialesPaso7()[1]}</p>}
          <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso7}>
                cancelar
          </Button>
      </Box>
  </>
}

    /*if (!paso_datos_personales_mas_ok){
      return <>
          <DatosPersonalesMas 
                usuario={usuario} 
                finalizar={finalizar} 
                nacionalidades={nacionalidades}
                estadosCiviles={estadosCiviles}
                tiposDoc={tiposDoc}
                paso_cumplido={paso_datos_personales_mas_ok}
                objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
            />
            <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
              {validarCondicionesInicialesPaso3()[0] && <Button variant='outlined' color='primary' onClick={iniciarContinuar_paso3}>Continuar</Button>}
              <Button style={{background:'tomato'}} variant="outlined" onClick={iniciarCancelar_paso3}>
                    cancelar
              </Button>
          </Box>
      </>
  }*/
    
     //if(continuar==true && paso_ubicacion_inicial_ok && paso_datos_personales_ok && paso_contacto_ok && paso_cuestionario_ok && paso_cuestionario2_ok){
     if(continuar==true){

      return <TramiteContextProvider>
      <Box className='form-body-tram' sx={{marginBottom:'25px'}}>
        <AlertDialog open={documento} 
                    titulo={'Vista de lectura, conformidad y aceptación del documento'} 
                    //style={{background:'rgb(96 147 179)'}}
                    //style2={{background:'rgb(186 203 213)'}}
                    style={{background:'white'}}
                    style2={{background:'white'}}
                    fullWidth={true}
                    maxWidth={'md'}
                    procesarCancelar = {()=>setDocumento(null)}
                   // procesarResultado={()=>setTramiteSeleccionado(null)}
                    error={''}>
                          <Aceptaciones1raCredencial tipo={documento} 
                                                      update={handleAceptacion} 
                                                      objLecturas={{lecturas,setLecturas}}
                                                      nombreDocumento={determinarNombreDocumento(documento)}
                                                      instancia='aceptacion' 
                                                      estado={determinarEstado(documento,objetoDinamico)}
                                                      finalizar={()=>setDocumento(null)}/>
            </AlertDialog>
        <Button variant='outlined' id="b-volver" color='primary' onClick={()=>setContinuar(false)}>Volver</Button>
        <Typography style={{marginTop:'25px'}} variant="h5">Solicitud de credenciales para nuevo ministro</Typography>
        <p style={{color:'red'}}>* Indica que el campo es obligatorio</p>
           <Box style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
            {/*<h1>UBICAR AQUÍ ALGUN formulario auxiliar al formik</h1>*/}
            {loadingFile && <LoaderCircular/>}
            {/*<Box>
                <h4>Texto: <span className='fw-400'>Texto</span></h4>
            </Box>*/}
            <h3 className=''>Por favor adjunte los siguientes archivos:</h3>
            <table className='table-adjuntos-1racre'>
              <tbody>
                <tr>
                  <td>
                  <SubirArchivo texto={!analiticoFile ? "" : ''} 
                          titulo={`Analítico de estudos teológicos en formato PDF`}
                          file={analiticoFile}
                          tiposFiles={['.pdf']}
                          maximo={5000000}
                          setFile={(setAnaliticoFile)}/>
                {loadingFile && <LoaderCircular/>}
                  </td>
                </tr>
                <tr>
                  <td>
                  <SubirArchivo texto={!dniFrenteFile ? "" : ''} 
                          titulo={`Foto de su DNI (Frente) en formato jpg/jpeg/png`}
                          file={dniFrenteFile}
                          maximo={500000}
                          tiposFiles={['.png','.jpg','.jpeg']}
                          setFile={(setDniFrenteFile)}/>
                {loadingFile && <LoaderCircular/>}
                  </td>
                </tr>
                <tr>
                    <td>
                    <SubirArchivo texto={!dniReversoFile ? "" : ''} 
                          titulo={`Foto de su DNI (Reverso) en formato jpg/jpeg/png`}
                          file={dniReversoFile}
                          tiposFiles={['.png','.jpg','.jpeg']}
                          maximo={500000}
                          setFile={(setDniReversoFile)}/>
                  {loadingFile && <LoaderCircular/>}
                    </td>
                </tr>
                <tr>
                  <td>
                  <SubirArchivo texto={!foto4x4File ? "" : ''} 
                          titulo={`Foto 4 x 4 en formato jpg/jpeg/png`}
                          file={foto4x4File}
                          maximo={500000}
                          tiposFiles={['.png','.jpg','.jpeg']}
                          setFile={(setFoto4x4File)}/>
                {loadingFile && <LoaderCircular/>}
                  </td>
                </tr>
                <tr>
                  <td>
                  <SubirArchivo texto={!antecedentes_penFile ? "" : ''} 
                          titulo={`Certificado de antecedentes penales en formato pdf`}
                          file={antecedentes_penFile}
                          maximo={5000000}
                          tiposFiles={['.pdf']}
                          setFile={(setAntecedentesPenFile)}/>
                {loadingFile && <LoaderCircular/>}
                  </td>
                </tr>
                <tr>
                  <td>
                  <SubirArchivo texto={!otroArchivo ? "" : ''} 
                          titulo={`Archivo extra (opcional)`}
                          file={otroArchivo}
                          opcional={true}
                          tiposFiles={['.pdf']}
                          maximo={5000000}
                          setFile={(setOtroArchivo)}/>
                {loadingFile && <LoaderCircular/>}
                  </td>
                </tr>
                {/*<tr>
                  <td>
                  {(objetoDinamico?.estado_civil?.id==3 || objetoDinamico?.estado_civil?.id==6) && <>
                    <SubirArchivo texto={!certificadoMatrimonioFile ? "" : ''} 
                            titulo={`Certificado de matrimonio en formato pdf`}
                            file={certificadoMatrimonioFile}
                            tiposFiles={['.pdf']}
                            setFile={(setCertificadoMatrimonioFile)}/>
                    {loadingFile && <LoaderCircular/>}
                </>}
                  </td>
                  </tr>*/}
              </tbody>
            </table>

            <h3 className=''>Por favor revise y acepte cada uno de los siguientes documentos:</h3>
            <table className='table-adjuntos-1racre'>
              <tbody>
                <tr>
                  <td style={{display:'flex',alignItems:'center',padding:'15px',position:'relative'}}> 
                      <TextSnippetIcon color="success" />
                      <p><span title='Visualice el documento de Estatutos y Testimonio UAD' className='cursor-pointer color-blue fw-600 p-2' onClick={()=>setDocumento('estatutos')}>Testimonio y Estatutos de la Unión de las Asambleas de Dios</span></p>
                      {objetoDinamico?.estatutos!=true && <p style={{right:'0',bottom:'0',position:'absolute',color:'red'}}>Aún no aceptó el documento</p>}
                      {objetoDinamico?.estatutos==true && <p style={{right:'0',bottom:'0',position:'absolute',color:'blue'}}>Aceptado</p>}
                  </td>
                </tr>
                <tr>
                <td style={{display:'flex',alignItems:'center',padding:'15px',position:'relative'}}> 
                      <TextSnippetIcon color="success" />
                      <p><span title='Visualice el documento de Doctrinas Fundamentales UAD' className='cursor-pointer color-blue fw-600' onClick={()=>setDocumento('doctrina')}>Doctrinas Fundamentales de la Unión de las Asambleas de Dios</span></p>
                      {objetoDinamico?.doctrina!=true && <p style={{right:'0',bottom:'0',position:'absolute',color:'red'}}>Aún no aceptó el documento</p>}
                      {objetoDinamico?.doctrina==true && <p style={{right:'0',bottom:'0',position:'absolute',color:'blue'}}>Aceptado</p>}
                  </td>
                </tr>
                <tr>
                <td style={{display:'flex',alignItems:'center',padding:'15px',position:'relative'}}> 
                      <TextSnippetIcon color="success" />
                      <p><span title='Visualice el documento de Compromiso Ministerial UAD' className='cursor-pointer color-blue fw-600' onClick={()=>setDocumento('compromiso')}>Compromiso Ministerial de la Unión de las Asambleas de Dios</span></p>
                      {objetoDinamico?.compromiso!=true && <p style={{right:'0',bottom:'0',position:'absolute',color:'red'}}>Aún no aceptó el documento</p>}
                      {objetoDinamico?.compromiso==true && <p style={{right:'0',bottom:'0',position:'absolute',color:'blue'}}>Aceptado</p>}
                  </td>
                </tr>
              </tbody>
            </table>

                {/*<>
                <SubirArchivo texto={!analiticoFile ? "" : ''} 
                          titulo={`Adjunte el Analítico de estudos teológicos en formato PDF`}
                          file={analiticoFile}
                          tiposFiles={['.pdf']}
                          setFile={(setAnaliticoFile)}/>
                {loadingFile && <LoaderCircular/>}
                <SubirArchivo texto={!dniFrenteFile ? "aaaa" : 'bbbb'} 
                          titulo={`Adjunte la Foto de su DNI (Frente) en formato jpg/jpeg/png`}
                          file={dniFrenteFile}
                          tiposFiles={['.png','.jpg','.jpeg']}
                          setFile={(setDniFrenteFile)}/>
                {loadingFile && <LoaderCircular/>}

                <SubirArchivo texto={!dniReversoFile ? "" : ''} 
                          titulo={`Adjunte la Foto de su DNI (Reverso) en formato jpg/jpeg/png`}
                          file={dniReversoFile}
                          tiposFiles={['.png','.jpg','.jpeg']}
                          setFile={(setDniReversoFile)}/>
                {loadingFile && <LoaderCircular/>}

                <SubirArchivo texto={!foto4x4File ? "" : ''} 
                          titulo={`Adjunte su Foto 4 x 4 en formato jpg/jpeg/png`}
                          file={foto4x4File}
                          tiposFiles={['.png','.jpg','.jpeg']}
                          setFile={(setFoto4x4File)}/>
                {loadingFile && <LoaderCircular/>}
                
                {(objetoDinamico?.estado_civil?.id==3 || objetoDinamico?.estado_civil?.id==6) && <>
                    <SubirArchivo texto={!certificadoMatrimonioFile ? "" : ''} 
                            titulo={`Adjunte el Certificado de matrimonio en formato pdf`}
                            file={certificadoMatrimonioFile}
                            tiposFiles={['.pdf']}
                            setFile={(setCertificadoMatrimonioFile)}/>
                    {loadingFile && <LoaderCircular/>}
                </>}
                </>*/}

                {/*<Box><FormGroup>
                    <FormControlLabel control={<Checkbox onChange={handleCheck} name={`compromiso`}/>} label="Acepto el compromiso"/>
                    <FormControlLabel control={<Checkbox onChange={handleCheck} name={`doctrina`}/>} label="Acepto la doctrina"/>
                    <FormControlLabel control={<Checkbox onChange={handleCheck} name={`estatutos`}/>} label="Acepto los estatutos"/>
                </FormGroup> 
                </Box>*/}
              {/*<UbicacionIglesia
                  usuario={usuario} 
                  finalizar={finalizar} 
                  provincias={provincias}
                  partidos={partidos}
                  validacion={validarCondicionesUbicacionIglesia}
                  validar={validarCampos}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />*/}
              <DatosPersonales 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  nacionalidades={nacionalidades}
                  estadosCiviles={estadosCiviles}
                  tiposDoc={tiposDoc}
                  validacion={validarCondicionesInicialesPaso1}
                  validar={validarCampos}
                  paso_cumplido={paso_datos_personales_ok}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
                {(objetoDinamico?.estado_civil?.id==3 || objetoDinamico?.estado_civil?.id==6) && <table>
                    <tbody>
                      <tr>
                        <td>
                            
                              <SubirArchivo texto={!certificadoMatrimonioFile ? "" : ''} 
                                      titulo={`Certificado de matrimonio en formato pdf`}
                                      file={certificadoMatrimonioFile}
                                      tiposFiles={['.pdf']}
                                      maximo={5000000}
                                      setFile={(setCertificadoMatrimonioFile)}/>
                              {loadingFile && <LoaderCircular/>}
                            
                            </td>
                      </tr>
                    </tbody>
                </table>}
               {/*<DatosPersonalesMas 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  nacionalidades={nacionalidades}
                  estadosCiviles={estadosCiviles}
                  tiposDoc={tiposDoc}
                  paso_cumplido={paso_datos_personales_mas_ok}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />*/}
              {/* La condición objetoDinamico?.paso_1 es para que se dispare primero la ubicación y luego el encargado */}
              <UbicacionInicial 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  provincias={provincias}
                  paso_cumplido={paso_ubicacion_inicial_ok}
                  partidos={partidos}
                  validacion={validarCondicionesInicialesPaso2}
                  validar={validarCampos}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
               <Contacto 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  validar={validarCampos}
                  validacion={validarCondicionesInicialesPaso3}
                  paso_cumplido={paso_contacto_ok}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
               <Cuestionario 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  validacion={validarCondicionesInicialesPaso4}
                  validar={validarCampos}
                  paso_cumplido={paso_cuestionario_ok}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
               <Cuestionario2 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  validar={validarCampos}
                  validacion={validarCondicionesInicialesPaso5}
                  paso_cumplido={paso_cuestionario2_ok}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
               <Pastores 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  validar={validarCampos}
                  validacion={validarCondicionesInicialesPaso6}
                  paso_cumplido={paso_pastores_ok}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
               <Referencias 
                  usuario={usuario} 
                  finalizar={finalizar} 
                  validar={validarCampos}
                  validacion={validarCondicionesInicialesPaso7}
                  paso_cumplido={paso_referencias_ok}
                  objetoModificacion={{obj:objetoDinamico,set:setObjetoDinamico}}
              />
              {<FormularioTramite finalizar={finalizar}  // función que se ejecuta al cancelar o terminar de guardar
                                 callbackPrevio = {callbackPrevio} // callback que se ejecuta antes de guardar y puede modificar o agregar campos que se envían a la mutation. Si están en el formik se van a pisar, si no se agregan
                                 objetoDinamico={objetoDinamico} // este objeto sirve para pasar valores por default a campos del formulario y también para pasar un contexto particular del trámite para que llegue esa info a los inputs que se van creando según la configuración del objeto json de campos del formulario
                                 campos_formulario={campos_formulario}
                                 textoSumbit="Enviar solicitud de nuevo ministro"
                                 callbackError={()=>eliminarArchivos(adjuntos)}
                                 cancelar={cancelar}
                                 CustomSubmit={CustomSubmit}
                                 preguntararSiCancela={{texto1:'Puede perder los datos cargados',texto2:'Por favor confirme la cancelación del formulario'}}
                                 callbackValidatePlus={callbackValidate} // callback de validación adicional custom
                                 useTramite = {useTramite}/>}
          </Box>
      </Box>
  </TramiteContextProvider>
     }

   
}




const createId = ()=>{
  const d = new Date();
  let dy = d.getDate()< 10 ? `0${d.getDate()}`: d.getDate();
  let mt = d.getMonth() < 10 ? `0${d.getMonth()}`: d.getMonth();
  let yr = d.getFullYear();
  let hs = d.getHours() < 10 ? `0${d.getHours()}`: d.getHours();
  let mn = d.getMinutes() < 10 ? `0${d.getMinutes()}`: d.getMinutes();
  let sc = d.getSeconds() < 10 ? `0${d.getSeconds()}`: d.getSeconds() ;

  const id = `${yr}${mt}${dy}${hs}${mn}${sc}`
  return id
}

const consulteAlgoAlBackend = ()=>{
    return new Promise((exito,rechazo)=>{

      setTimeout(() => {
        if(1<2){
          exito({data:{campox:59}})
        }else{
          rechazo()
        }
      }, 2000);
       
    })
}


const SeleccioneAlgo = ({setAlgoSeleccionado})=>{
    return <select onChange={(e)=>setAlgoSeleccionado(e.target.value)}>
        <option value="algo">algo</option>
        <option value="algo2">algo 2</option>
        <option value="algo3">algo 3</option>
    </select>
}

const validarUbicacion = (values)=>{

  if(!values?.partido?.id){
      return [false,`Falta identificar el partido`]
  }

  if(!values?.provincia?.id){
    return [false,`Falta identificar la provincia`]
  }

  if(!values?.distrito?.id){
    return [false,`Falta identificar el distrito`]
  }

  if(!values?.id_region){
    return [false,`Falta identificar la región`]
  }

  if(!values?.localidad){
    return [false,`Falta ingresar la localidad del nuevo ministro`]
  }

  if(!values?.cod_postal){
    return [false,`Falta ingresar el código postal del nuevo ministro`]
  }

  if(!values?.direccion){
    return [false,`Falta ingresar la dirección del nuevo ministro`]
  }

  return true
}

const numerosIguales = (telefono,celular)=>{
  
  if(typeof telefono == 'string' && typeof celular == 'string' ){
      if(telefono.trim()===celular.trim()){
          return true
      }else{
          return false
      }
  }else{
      return false
  }

}



const determinarNombreDocumento = (documento) =>{

  
  switch (documento){
    
    case 'doctrina':
      return 'Doctrinas fundamentales UAD'
    case 'estatutos':
      return 'Estatutos y testimonio UAD'
    case 'compromiso':
      return 'Compromiso ministerial UAD'
    default : return false
  } 
}

const determinarEstado = (documento,objetoDinamico) =>{

  
  switch (documento){
    
    case 'doctrina':
      return objetoDinamico?.doctrina==undefined ? null: objetoDinamico?.doctrina
    case 'estatutos':
      return objetoDinamico?.estatutos==undefined ? null: objetoDinamico?.estatutos
    case 'compromiso':
      return objetoDinamico?.compromiso==undefined ? null: objetoDinamico?.compromiso
    default : return false
  } 
}