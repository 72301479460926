import React,{useState,useEffect} from 'react'
import Axios from 'axios'
import Swal from 'sweetalert2';
import {useAlumno} from '../../../Context/alumnoContext'
import {gql,useQuery,useMutation,useLazyQuery} from '@apollo/client'
import REG_EX from '../../../Helpers/regex';
import moment from 'moment';

export const useTramite = ()=>{
    const {usuario} = useAlumno()
    const [objetoDinamico,setObjetoDinamico] = React.useState({usuario})
    const [provincias,setProvincias]=useState([])
    const [partidos,setPartidos]=useState([])
    const [ministros,setMinistros]=useState([])
    const [tiposDoc,setTiposDoc]=useState([])
    const [nacionalidades,setNacionalidades]=useState([])
    const [estadosCiviles,setEstadosCiviles]=useState([])

    React.useEffect(()=>{
        buscarTablas()
    },[])

    const actualizarObjetoDinamico = (campoNuevo)=>{
        setObjetoDinamico({...objetoDinamico,...campoNuevo})
    }

    const CONSULTAR_TRAMITES_POR_TIPO_MINISTRO = gql`
        query consultaTramites($tipo:String!,$usuario_id:Int!){
            tramitesTipoMinistro(tipo:$tipo,id_ministro:$usuario_id){
                id,
                codigo_web,
                fecha,
                status{
                    texto
                }
            }
        }
    `

const CONSULTAR_MINISTROS_POR_MINISTRO = gql`
query consultaMinistros($ministro_id:Int!){
    ministrosPorMinistro(ministro_id:$ministro_id){
        status_actual,
        status,
        id_tramite{
            codigo_web
        }
    }
}
`

    const CREAR_MINISTRO = gql`
mutation nuevoMinistro(  $nombre:String!,
                      $apellido:String!,
                      $sexo:String!,
                      $tipo_documento:propTypeInput!,
                      $nacionalidad:propTypeInput!,
                      $estado_civil:propTypeInput!,
                      $iglesia:propTypeInput!,
                      $nro_documento:Int!,
                      $fecha_nacimiento:String!,
                      $conyuge:String,
                      $direccion:String!,
                      $localidad:String!,
                      $barrio:String,
                      $cod_postal:String!,
                      $provincia:propTypeInput!,
                      $partido:propTypeInput!
                      $provinciaigl:propTypeInput!,
                      $partidoigl:propTypeInput!,
                      $telefono:String!,
                      $celular:String!,
                      $email:String!,
                      $bautismo_es:Boolean!,
                      $year_bautismo:Int!,
                      $year_conversion:Int!,
                      $diezmo:Boolean!,
                      $sostenimiento:String!,
                      $nombrepastor:String!,
                      $emailpastor:String!,
                      $nombreanciano:String!,
                      $emailanciano:String!,
                      $nombreconyuge:String,
                      $emailconyuge:String,
                      $pastores:[String!]!,
                      $region:Int!,
                      $solicitante: String!,
                      $id_solicitante:Int!,
                      $descripcion:String!,
                      $distrito:propTypeInput!,
                      $id_tramite_patrocinio:ID!,
                      $dni_frente:String!,
                      $dni_reverso:String!,
                      $analitico:String!,
                      $cert_mat:String,
                      $foto_4x4:String!,
                      $extra_1URL:String,
                      $antecedentes_pen:String!,
                      $doctrina:Boolean!,
                      $compromiso:Boolean!,
                      $estatutos:Boolean!
                      )
                      {
                        nuevoMinistro(
                                nombre:$nombre,
                                apellido:$apellido,
                                sexo:$sexo,
                                tipo_documento:$tipo_documento,
                                nacionalidad:$nacionalidad,
                                estado_civil:$estado_civil,
                                nro_documento:$nro_documento,
                                fecha_nacimiento:$fecha_nacimiento,
                                conyuge:$conyuge,
                                direccion:$direccion,
                                localidad:$localidad,
                                barrio:$barrio,
                                cod_postal:$cod_postal,
                                partido:$partido,
                                provincia:$provincia,
                                partidoigl:$partidoigl,
                                provinciaigl:$provinciaigl,
                                telefono:$telefono,
                                celular:$celular,
                                email:$email,
                                bautismo_es:$bautismo_es,
                                year_bautismo:$year_bautismo,
                                year_conversion:$year_conversion,
                                diezmo:$diezmo,
                                sostenimiento:$sostenimiento,
                                nombrepastor:$nombrepastor,
                                emailpastor:$emailpastor,
                                nombreanciano:$nombreanciano,
                                emailanciano:$emailanciano,
                                nombreconyuge:$nombreconyuge,
                                emailconyuge:$emailconyuge,
                                pastores:$pastores,
                                region:$region,
                                solicitante:$solicitante,
                                solicitante_id:$id_solicitante,
                                descripcion:$descripcion,
                                distrito:$distrito,
                                iglesia:$iglesia,
                                id_tramite_patrocinio:$id_tramite_patrocinio,
                                dni_frente:$dni_frente,
                                dni_reverso:$dni_reverso,
                                analitico:$analitico,
                                foto_4x4:$foto_4x4,
                                antecedentes_pen:$antecedentes_pen,
                                cert_mat:$cert_mat,
                                extra_1URL:$extra_1URL,
                                doctrina:$doctrina,
                                compromiso:$compromiso,
                                estatutos:$estatutos
                            )}`;

const callback = (values)=>{
    debugger
        const variables = {
            region:Number(values.id_region),
            solicitante:values.solicitante,
            id_solicitante:values.id_solicitante,
            descripcion:`Solicita credenciales ${values.nombre} ${values.apellido}`,
            usuario_id:Number(usuario?.id_obrero || 685),
            nombre:values.nombre,
            apellido:values.apellido,
            fecha_nacimiento:values.fecha_nacimiento,
            sexo:values.sexo,//values.sexo,
            nro_documento:Number(values.dni),
            conyuge:values.estado_civil.id==3 || values.estado_civil.id== 6 ? values.conyuge : null,
            tipo_documento:{id:Number(values.tipo_doc.id),nombre:values.tipo_doc.nombre},
	        nacionalidad:{id:Number(values.nacionalidad.id),nombre:values.nacionalidad.nombre},
	        estado_civil:{id:Number(values.estado_civil.id),nombre:values.estado_civil.nombre},
            //fecha_bautismo_agua:values.fecha_bautismo_a,
            //fecha_bautismo_es:values.bautismo_es=='SI' ? values.fecha_bautismo_es : null,
            //fecha_conversion:values.fecha_conversion,
            year_conversion:Number(values.year_conversion),
            year_bautismo:Number(values.year_bautismo),
            bautismo_es:values.bautismo_es=='SI' ? true : false,
            direccion:values.direccion,
            localidad:values.localidad,
            barrio:values.barrio,
            cod_postal:values.cod_postal,
            email:values.email,
            telefono:values.telefono,
            celular:values.celular,
            nombrepastor:values.nombrepastor,
            emailpastor:values.emailpastor,
            nombreanciano:values.nombreanciano,
            emailanciano:values.emailanciano,
            nombreconyuge:(values.estado_civil.id==3 || values.estado_civil.id==6) ? values.conyuge :null,
            emailconyuge:(values.estado_civil.id==3 || values.estado_civil.id==6) ? values.emailconyuge :null,
            pastores:values.pastores,
            diezmo:values.diezmo=='SI' ? true : false,
            sostenimiento:values.sostenimiento,
            partido:{id:Number(values.partido.id),nombre:values.partido.nombre},
            provincia:{id:Number(values.provincia.id),nombre:values.provincia.nombre},
            distrito:{id:Number(values.distrito.id),nombre:values.distrito.nombre},
            iglesia:{id:Number(values.iglesia.id),nombre:values.iglesia.nombre},
            provinciaigl:{id:Number(values.provinciaigl.id),nombre:values.provinciaigl.nombre},
            partidoigl:{id:Number(values.partidoigl.id),nombre:values.partidoigl.nombre},
            id_tramite_patrocinio:values.id_tramite_patrocinio,
            dni_frente:values.dni_frenteURL,
            dni_reverso:values.dni_reversoURL,
            analitico:values.analiticoURL,
            foto_4x4:values.foto_4x4URL,
            antecedentes_pen:values.antecedentes_penURL,
            cert_mat:(values.estado_civil.id==3 || values.estado_civil.id==6) ? values.cert_matURL :null,
            extra_1URL:values.extra_1URL, // viene del callbackPrevio
            doctrina:values.doctrina,
            compromiso:values.compromiso,
            estatutos:values.estatutos
        }
 return variables
}

const dniEstaEnUso = async (dni)=>{
    try{

        const resultado = await Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/validardni/${dni}`)
        if(resultado?.data?.status==true){
            return [true]
        }else{
            return [false,resultado?.data?.mensaje || 'No es posible utilizar el DNI'] 
        }
    }catch(err){
        console.log(err)
        return [false,'Hubo un error al validar el DNI']
    }
}

const keyNoValida = async (key)=>{
    try{

        const resultado = await Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/validarkey/${key}`)
        if(resultado?.data?.status==true){
            return [true]
        }else{
            return [false,resultado?.data?.mensaje || 'No es posible utilizar el linnk'] 
        }
    }catch(err){
        console.log(err)
        return [false,'Hubo un error al validar el link']
    }
}




const buscarTablas = async ()=>{
    try{
        const data = await Promise.all([Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/provincias`),
                                        Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/partidos`),
                                       // Axios.get(`${process.env.REACT_APP_ROOT}/api/tablasgenerales/ministros/todos`),
                                        Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/tiposdocumento`),
                                        Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/nacionalidades`),
                                        Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/estadosciviles`)])
        
        setProvincias(data[0].data.filter(item=>item.id_provincia>0).map(item=>{
            return {id:item.id_provincia,nombre:item.nombre}
        }))

        setPartidos(data[1].data.partidos)

        /*const data_aux = data[2].data.filter(item=>item?.id_obrero>0)
        .map(item=>{return {id:item?.id_obrero || 685,nombre:item.nombre_obrero}})
        setMinistros(data_aux)
        */
        setTiposDoc(data[2].data.filter(i=>i.id_tipo_doc>0).map(i=>{return{id:i.id_tipo_doc,nombre:i.nombre}}))
        setNacionalidades(data[3].data.filter(i=>i.id_nacionalidad>0).map(i=>{return{id:i.id_nacionalidad,nombre:i.nombre}}))
        setEstadosCiviles(data[4].data.filter(i=>i.id_estado_civil>0).map(i=>{return{id:i.id_estado_civil,nombre:i.nombre}}))
    }catch(err){
        alert(err)
    }
}

/*const {loading,error,data,refetch} = useQuery(CONSULTAR_TRAMITES_POR_TIPO_MINISTRO,
    {variables:{usuario_id:usuario.id_obrero,tipo:'ascenso'},
//    pollInterval: 5000000
})
*/
const [listadoTramitesporMinistro,{error,data,loading}] = useLazyQuery(CONSULTAR_TRAMITES_POR_TIPO_MINISTRO,
    {variables:{usuario_id:usuario?.id_obrero || 685,tipo:'ministro'}})

const [ministrosPorMinistroDetalle] = useLazyQuery(CONSULTAR_MINISTROS_POR_MINISTRO) // las variables las paso como un objeto 
                                                                                    // al ejecutar

const campos_formulario = []

const fechaEsValida = (fecha)=>{
    const fecha_hoy = moment();
    const fechaHoyMenos100 = moment().subtract(100, "years");
    const fecha_aux = moment(fecha)
    if(!(fecha_aux.isBetween(fechaHoyMenos100,fecha_hoy))){
      return false
    }
    return true  
  }

    return {
        usuario,
        mutationPadre:CREAR_MINISTRO,
        variables:callback,
        listadoTramitesporMinistro,
        objetoDinamico,
        actualizarObjetoDinamico,
        campos_formulario,
        ministrosPorMinistroDetalle,
        provincias,
        partidos,
        //ministros,
        tiposDoc,
        nacionalidades,
        estadosCiviles,
        fechaEsValida,dniEstaEnUso,
        keyNoValida
    }
}

const callbackFilterDinamicoRegiones = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return (item)=>item.id_region==objetoDinamico.otroMinistro.id_region
    }else{
        return (item)=>item.id_region==objetoDinamico.usuario.id_region
    }
}

const callbackFilterDinamicoDistrito = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return (item)=>item.id_distrito==objetoDinamico.otroMinistro.id_distrito
    }else{
        return (item)=>item.id_distrito==objetoDinamico.usuario.id_distrito
    }
}

const vectorStatusDinamico = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return [{id:objetoDinamico.otroMinistro.rango,value:objetoDinamico.otroMinistro.rango}]
    }else{
        return [{id:objetoDinamico.usuario.rango,value:objetoDinamico.usuario.rango}]
    }
}

const vectorMinistroDinamico = (objetoDinamico)=>{
    if(objetoDinamico?.otroMinistro){
        return [{id:objetoDinamico.otroMinistro.id_ministro,value:objetoDinamico.otroMinistro.ministro}]
    }else{
        return [{id:objetoDinamico.usuario?.id_obrero || 685,value:objetoDinamico.usuario.nombre}]
    }
}
