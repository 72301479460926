import React, {useEffect,useState,useContext} from 'react'
import { Box,Typography,Button, Snackbar} from "@material-ui/core";
import Divider from '@mui/material/Divider';
import { AlertDialog } from '../../../componentes/MUI/AlertDialog';
import {FormularioTramite} from '../../FormularioTramite'
import {TramiteContextProvider} from '../contexto/tramiteContext'
import {tramiteContext} from '../contexto/tramiteContext'
import {useTramite} from '../hooks/useTramite'
import { DropzoneArea } from "material-ui-dropzone";
import { SubirArchivo } from '../../../componentes/formulariosFormik/SubirArchivo';
import Axios from 'axios';
import {LoaderCircular} from '../../../componentes/LoaderCircular'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useAlumno} from '../../../Context/alumnoContext'
import { faWindowClose,faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Dependientes} from '../componentes/Dependientes'
import Alert from '@mui/material/Alert';
import moment from 'moment';
import {doPostFile} from '../../../Helpers/utilidades-globales'
import { json } from 'react-router-dom';

export const InicioTramite = ({finalizar,id})=>{
    const [iniciar,setIniciar] = useState(true) // la primera vez es null, luego si cancela N veces será false, importante para DatosPrimarios
    const [dataFile, setDataFile] = React.useState('');
    const [analiticoFile, setAnaliticoFile] = React.useState(null);
    const [otroArchivo, setOtroArchivo] = React.useState(null);
    const [compromisoFile, setCompromisoFile] = React.useState(null);
    const [analiticoURL,setAnaliticoURL]=React.useState(null);
    const [compromisoURL,setCompromisoURL]=React.useState(null);
    const [loadingFile,setLoadingFile] = React.useState(false)
    const {usuario,snackbar} = useAlumno()
    const {listadoTramitesporMinistro,campos_formulario,ascensosPorMinistroDetalle,updateAscenso,ascensoVigente} = useTramite()
    const [ministroSolicitud,setMinistroSolicitud]=React.useState(null)
    const [dependienteSeleccionado,setDependienteSeleccionado] = React.useState(null)
    const [dependientes,setDependientes] = React.useState(null)
    const [objetoDinamico,setObjetoDinamico] = React.useState({usuario});
    const [continuar,setContinuar]=useState(false)
    const [infoMinistroAscenso,setInfoMinistroAscenso] = React.useState(null)
    const [updateTramite,setUpdateTramite] = React.useState(null)
    const [ministroValidado,setMinistroValidado] = React.useState(null)

    React.useEffect(()=>{

      const buscarAscenso = async (id)=>{
          try{
            const {data} = await updateAscenso({variables:{id}})
            setUpdateTramite(data?.ascensoInfo)
            setObjetoDinamico({
              status_actual:data?.ascensoInfo.status_actual,
              region:Number(data?.ascensoInfo.id_tramite.region),
              distrito:data?.ascensoInfo.id_tramite.distrito.nombre,
              distrito_id:Number(data?.ascensoInfo.id_distrito.id),
              ministro:data?.ascensoInfo.ministro,
              ministro_id:Number(data?.ascensoInfo.ministro_id),
              usuario:usuario.nombre,
              descripcion:`Solicita ascenso de ${data?.ascensoInfo.ministro}`,
              usuario_id:Number(usuario.id_obrero),
              analiticoURL:data?.ascensoInfo.analiticoURL // viene del callbackPrevio
            })
          }catch(err){
              alert(err)
          }
      }

      if(id){
        buscarAscenso(id)
      }
    },[])

    React.useEffect(()=>{

      if(ministroSolicitud){
        
          if(ministroSolicitud!=usuario.id_obrero){
              if(dependientes && dependientes.length>0){
                const datosDependiente = dependientes.filter(item=>item.id==ministroSolicitud)


                setObjetoDinamico({...objetoDinamico,otroMinistro:{id_region:datosDependiente[0].id_region,
                                                                    id_distrito:datosDependiente[0].id_distrito,
                                                                    rango:datosDependiente[0].rango,
                                                                    ministro:datosDependiente[0].nombre,
                                                                  id_ministro:datosDependiente[0].id}})
              }else{
                  alert('No se encontraron los datos de los ministros dependientes')
                  setMinistroSolicitud(null)
              }
          }

          setInfoMinistroAscenso(datosMinistroSeleccionado(ministroSolicitud,usuario,dependientes))
      }else{
        setInfoMinistroAscenso(null)
        setObjetoDinamico({...objetoDinamico,otroMinistro:null})
      }
  },[ministroSolicitud])

  React.useEffect(()=>{
      if(infoMinistroAscenso){
          validarAscensoVigente(infoMinistroAscenso?.id || infoMinistroAscenso?.id_obrero)
          .then(respuesta=>{
              if(respuesta){
                  snackbar.showMessage(respuesta,'error')
                  setMinistroSolicitud(null)
                  setMinistroValidado(false)
              }else{
                  setMinistroValidado(true)
              }
          })
          .catch(err=>{
              snackbar.showMessage(err,'error')
              setMinistroSolicitud(null)
              setMinistroValidado(false)
          })
      }

  },[infoMinistroAscenso])
    /*
    React.useEffect(()=>{

      if(ministroSolicitud){
        
          if(ministroSolicitud!=usuario.id_obrero){
              if(dependientes && dependientes.length>0){
                const datosDependiente = dependientes.filter(item=>item.id==ministroSolicitud)


                setObjetoDinamico({...objetoDinamico,otroMinistro:{id_region:datosDependiente[0].id_region,
                                                                    id_distrito:datosDependiente[0].id_distrito,
                                                                    rango:datosDependiente[0].rango,
                                                                    ministro:datosDependiente[0].nombre,
                                                                  id_ministro:datosDependiente[0].id}})
              }else{
                  alert('No se encontraron los datos de los ministros dependientes')
                  setMinistroSolicitud(null)
              }
          }

          setInfoMinistroAscenso(datosMinistroSeleccionado(ministroSolicitud,usuario,dependientes))
      }else{
        setInfoMinistroAscenso(null)
        setObjetoDinamico({...objetoDinamico,otroMinistro:null})
      }
  },[ministroSolicitud])

    React.useEffect(()=>{

        if(ministroSolicitud){

            validarAscensoVigente(Number(ministroSolicitud))
            .then(respuesta=>{
                if(respuesta){
                    setMinistroValidado(false)
                    snackbar.showMessage(respuesta,'error')
                    setInfoMinistroAscenso(null)

                }else{
                  
                  setMinistroValidado(true)

                  if(ministroSolicitud!=usuario.id_obrero){
                    if(dependientes && dependientes.length>0){
                      const datosDependiente = dependientes.filter(item=>item.id==ministroSolicitud)
    
    
                      setObjetoDinamico({...objetoDinamico,otroMinistro:{id_region:datosDependiente[0].id_region,
                                                                          id_distrito:datosDependiente[0].id_distrito,
                                                                          rango:datosDependiente[0].rango,
                                                                          ministro:datosDependiente[0].nombre,
                                                                        id_ministro:datosDependiente[0].id}})
                    }else{
                        alert('No se encontraron los datos de los ministros dependientes')
                        setMinistroSolicitud(null)
                    }
                }else{
                  setInfoMinistroAscenso(datosMinistroSeleccionado(ministroSolicitud,usuario,dependientes))
                }
              }
            })
            .catch(err=>{
              setMinistroSolicitud(null)
              setMinistroValidado(false)
            })

        }else{
          setInfoMinistroAscenso(null)
          setObjetoDinamico({...objetoDinamico,otroMinistro:null})
        }

    },*/
    const callbackPrevio = async ()=>{

      try{

        setLoadingFile(true)

        if(!analiticoFile)
        {
          setLoadingFile(false)
          return [false,'Falta adjuntar el archivo de analítico']
        }

        /*if(!compromisoFile)
        {
          setLoadingFile(false)
          return [false,'Falta adjuntar el archivo de compromiso ministerial']
        }*/

        const resultado_analitico = await doPostFile(analiticoFile,usuario,'analitico','pdf')
       // const resultado_compromiso = await doPostFile(compromisoFile)

        if(!resultado_analitico[0])
        {
          setLoadingFile(false)
          return [false,resultado_analitico[1]]
        }else{
            setAnaliticoURL(resultado_analitico[1])
        }

        /*if(!resultado_compromiso[0])
        {
          setLoadingFile(false)
          return [false,resultado_compromiso[1]]
        }else{
          setCompromisoURL(resultado_compromiso[1])
        }*/
        
        let resultado_otroArchivo;

        if(otroArchivo){
          resultado_otroArchivo = await doPostFile(otroArchivo,usuario,'extra_1','pdf')
          // const resultado_compromiso = await doPostFile(compromisoFile)
   
           if(!resultado_otroArchivo[0])
           {
             setLoadingFile(false)
             return [false,resultado_otroArchivo[1]]
           }
          
        }

        setLoadingFile(false)


        return [true,{
          analiticoURL:resultado_analitico[1],
          ...(resultado_otroArchivo ? {
            extra_1URL:resultado_otroArchivo[1]
          }:{})
          //ministro:'Prueba testing',    // AQUI PUEDO ENVIAR TODO TIPO DE DATOS QUE NO ESTAN EN EL
          //id_ministro:33                // FORMULARIO PERO QUE DESEAMOS QUE LLEGUEN A LA MUTACIÓN
                                          // O QUE ESTAN EN EL FORM PERO QUEREMOS QUE SE PISEN
        }]
      }catch(err){
        alert(err)
      }
    } 

     // Uso la función versión promise para poder capturar y procesar los errores
     const loadDataload = async (form) => {
      try {
          const respuesta = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/upload`,form,{headers: { 'Content-Type': 'multipart/form-data' }})
          console.log('aaa',respuesta)
          return respuesta.data;
      } catch (error) {
        console.log('bbb',error)
        return error;
      }
    };

    const validarAscensoVigente = async (id)=>{
      try{
        const {data} = await ascensoVigente({variables:{ministro_id:ministroSolicitud}})
        if(data.ascensoSolicitado){
            return data.ascensoSolicitado
        }else{
            return null
        }
      }catch(err){
          return null
      }
    }
    
    const validarCondicionesIniciales = ()=>{

      if(infoMinistroAscenso){
        if(infoMinistroAscenso?.fecha_ascenso!==undefined){ // compararación estricta para comprobar que vino el campo
            if(infoMinistroAscenso.fecha_ascenso){ 
                  const fecha_ascenso = infoMinistroAscenso.fecha_ascenso;
                  const fecha_asc_moment = moment(fecha_ascenso).format('DD/MM/YYYY')
                  const fecha_mas_2_años = moment(fecha_ascenso).add(2,'years');
                  const fecha_actual = moment()
                  if(fecha_actual.isBefore(fecha_mas_2_años)){
                      return [false,`No es posible solicitar un nuevo ascenso para ${infoMinistroAscenso.nombre}. Se requiere que transcurran al menos dos años desde el último ascenso (${fecha_asc_moment}). Puede solicitarlo a partir de ${fecha_mas_2_años.format('DD/MM/YYYY')} `]
                  }else{
                    return [true]
                  }
            }else{
                return [true] // null o vacio avanzar porque nunca tuvo ascenso
            }
        }else{
          return [false,'No se pudo identificar la fecha de último ascenso']
        }
      }else{
          return [false,'No seleccionó un ministro para ascender']
      }
    }

    const iniciarContinuar = ()=>{
          
      if(!validarCondicionesIniciales()[0]){
        //alert(validarCondicionesIniciales()[1])
        setMinistroSolicitud(null)
        snackbar.showMessage(validarCondicionesIniciales()[1],'error')
        return
      }
      setContinuar(true)
    }

    const callbackValidate = async (values)=>{

      if(!analiticoFile)
      {
        return [false,'Falta adjuntar el archivo de analítico']
      }

      /*
      // ESTA QUERY LA USABA ANTES PERO CONSULTABA SOLO LA CABECERA Y AHÍ NO ESTAN LOS DATOS DEL MINISTRO SINO SOLO DEL SOLICITANTE
      const {data} = await listadoTramitesporMinistro()

      if(listadoTramitesDelMinistro){

        if(listadoTramitesDelMinistro.length>0){
          debugger
            listadoTramitesDelMinistro.forEach(item=>{
                const ultimoEstado = item.status[item.status.length-1]
                if (ultimoEstado.texto!='Cerrado'){
                  listadoValidado=false;
                }
            })
        }
    }
    */

      const {data : data2} = await ascensosPorMinistroDetalle({variables:{ministro_id:values.id_ministro}})

      let listadoValidado = true;

      const listadoAsencosDelMinistro = data2.ascensosPorMinistro;

      if(listadoAsencosDelMinistro){
          if(listadoAsencosDelMinistro.length>0){
              listadoAsencosDelMinistro.forEach(item=>{
                  if (!(item.status=='Aprobado' || item.status=='Rechazado')){
                    listadoValidado=false;
                  }
              })
          }
      }

      if(!listadoValidado){
          return [false,`Existe un trámite de ascenso en curso para el ministro ${values.ministro}. Verifique su listado de trámites o contacte a la sede central`]
      }

      //const validate_listado = listado.filter(item)
      return [true]

    }

    // para visualizar el json de usuario
/*
    return <Box>
        {Object.entries(usuario).map(item=>{
            return <p>{item[0]} ---- {item[1]}</p>
        })}
    </Box>
*/
  if(!updateTramite){
    if (usuario.id_iglesia_dependiente){
      return <Box sx={{width:'100%',height:'200px',display:'flex',justifyContent:'center',flexDirection:'column'}}>
          <p>Debe solicitar el ascenso al ministro a cargo de:</p>
          <p className='mt-4'>{usuario.dependiente}</p>
          <Button style={{marginTop:'15px'}} variant="outlined" color='primary' onClick={finalizar}>Entendido</Button>
      </Box>  
    }

    if ((Number(usuario.dependientes)>0 && !ministroSolicitud)){
      return <Box sx={{width:'100',display:'flex',justifyContent:'center',flexDirection:'column'}}>
           <Cabecera finalizar={finalizar}/>
           <Textos/>           
          <b className='text-center'>Encontramos que usted tiene {usuario.dependientes == 1 ? ' 1 ministro que depende ' : `${usuario.dependientes} ministros que dependen` } de su ministerio</b>
          <p className='mt-4'>Por favor indique para quien es la solicitud de ascenso</p>
      <Button style={{marginTop:'15px'}} variant="outlined" color='primary' onClick={()=>setMinistroSolicitud(usuario.id_obrero)}>Para mí</Button>
      <Dependientes usuario={usuario} 
                    dependienteSeleccionado = {dependienteSeleccionado} 
                    setDependienteSeleccionado={setDependienteSeleccionado}
                    setDependientes={setDependientes}/>
      {dependienteSeleccionado && <Button style={{marginTop:'15px'}} variant="outlined" color='primary' onClick={()=>setMinistroSolicitud(dependienteSeleccionado)}>PARA EL DEPENDIENTE SELECCIONADO</Button>}
    </Box>  
    }
    /*
    FUE NECESARIO agregar un flag para que luego de seleccionar el ministroSolicitud
    no renderice inmediatamente el componente FormularioTramite.
    Sucedía que apenas se seleccionaba un ministroSolicitud se renderizadaba el formik
    antes de que se actualice el objeto objetoDinamico con la información dinámica que se necesita
    del contexto para poder cargar correctamente los selects de status, region, distrito y ministro.
    Se usa un flag de "Continuar" para que renderice algo antes y de tiempo a que se ejecuten los
    useEffects de éste componente y se actualice el objetoDinmico antes de renderizar el formik.
    El flujo es, primero se renderiza, luego se ejecutan los useEffects entonces hay situaciones en que
    hay que implementar lógica para que cada acción sea en el momento adecuado.
    En este caso 1) seleccionar un ministroSolicitud, luego actualizar objetiDinamico luego renderizar
    el formulario formik con el contexto dinámico correcto.
    Sucedía que primero montaba el formulario, renderizaba el mismo, ejecutaba un useEffect del formik
    y luego procesaba los useEffects del componente InicioTramite y quedaba mal sincronizado el proceso.
    Pensaba que era un problema de closures con las funciones allowedValues de campos_formuularios
    pero no era un closure sino un problema de renderizado antes de tiempo del componente.
    Se resolvió con un flag y un componente de confirmación previo.
    */
    if(ministroSolicitud && !continuar){

      if(!infoMinistroAscenso){
          return <Box className='form-body-tram'>
              <Cabecera finalizar={finalizar}/>

              <Box sx={{margin:'5px',padding:'5px'}}>
                  <Alert variant="filled" severity="warning">
                      No se encontraron los datos del ministro seleccionado.
                  </Alert>
              </Box>

          </Box>
      }

      return <Box>
          <Cabecera finalizar={finalizar}/>
          <Textos/>           
          <Box sx={{margin:'5px',padding:'5px'}}>
              <h4>Ministro seleccionado: <span className='fw-400'>{infoMinistroAscenso.nombre}</span></h4>
              <h4>Fecha de último ascenso: <span className='fw-400'>{infoMinistroAscenso?.fecha_ascenso ? infoMinistroAscenso?.fecha_ascenso :  'No registrada' }</span></h4>
          </Box>
          <Alert variant="filled" severity="info" sx={{background:'#0288d1'}}>
              Recuerde que se requiere adjuntar el Analítico de estudios del ministro: {infoMinistroAscenso?.nombre} en formato PDF
          </Alert>
          <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
              <Button variant='outlined' color='primary' onClick={iniciarContinuar}>Continuar</Button>
              <Button style={{background:'tomato'}} variant="outlined" onClick={()=>setMinistroSolicitud(null)}>
                    cancelar
              </Button>
          </Box>
      </Box>
    }
  }
   
  return <TramiteContextProvider>
      <Box className='form-body-tram' sxxxx={{marginBottom:'25px',background:'#b3b0b0', padding:'20px', marginTop:'20px'}}>
           <Cabecera finalizar={finalizar}/>
           <p style={{color:'red'}}>* Indica que el campo es obligatorio</p>
           <Box>
            {/*<SubirArchivo texto={!analiticoFile ? "" : '...'} 
                          titulo={`Se requiere adjuntar "Analítico de estudos teológicos" en formato PDF`}
                          file={analiticoFile}
                          tiposFiles={['.pdf']}
                          setFile={(setAnaliticoFile)}/>*/}
            {/*loadingFile && <LoaderCircular/>*/}
            <h3 className=''>Por favor adjunte el siguiente archivo:</h3>
            <table className='table-adjuntos-1racre'>
              <tbody>
                <tr>
                  <td>
                  <SubirArchivo texto={!analiticoFile ? "" : ''} 
                          titulo={`Analítico de estudios teológicos en formato PDF`}
                          file={analiticoFile}
                          tiposFiles={['.pdf']}
                          maximo={5000000}
                          setFile={(setAnaliticoFile)}/>
                {loadingFile && <LoaderCircular/>}
                  </td>
                </tr>
                <tr>
                  <td>
                  <SubirArchivo texto={!otroArchivo ? "" : ''} 
                          titulo={`Archivo opcional`}
                          file={otroArchivo}
                          opcional={true}
                          tiposFiles={['.pdf']}
                          maximo={5000000}
                          setFile={(setOtroArchivo)}/>
                {loadingFile && <LoaderCircular/>}
                  </td>
                </tr>
              </tbody>
              </table>
              
            <Box>
                <h4>Fecha de último ascenso: <span className='fw-400'>{infoMinistroAscenso?.fecha_ascenso ? infoMinistroAscenso?.fecha_ascenso :  'No registrada' }</span></h4>
            </Box>
              <FormularioTramite finalizar={finalizar}  // función que se ejecuta al cancelar o terminar de guardar
                                 callbackPrevio = {callbackPrevio} // callback que se ejecuta antes de guardar
                                 objetoDinamico={objetoDinamico} // este objeto sirve para pasar valores por default a campos del formulario y también para pasar un contexto particular del trámite para que llegue esa info a los inputs que se van creando según la configuración del objeto json de campos del formulario
                                 campos_formulario={campos_formulario}
                                 textoSumbit="Enviar solicitud de ascenso"
                                 callbackValidatePlus={callbackValidate} // callback de validación adicional custom
                                 useTramite = {useTramite}/> 
          </Box>
      </Box>
</TramiteContextProvider>

   
}

const Cabecera = ({finalizar})=>{
  return <Box sx={{marginBottom:'20px'}}>
     <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'25px'}}>
     <Typography variant="h5">Solicitud de ascenso</Typography>
      <div className='cursor-pointer text-center ml-2' title='Cerrar' onClick={finalizar}>
            <FontAwesomeIcon className="color-red text-xlarge"  icon={faTimesCircle}/>
      </div>
    </Box>

    <div style={{position:'absolute',top:'0',left:'0',width:'100%'}}>
    
    </div>
 </Box>
}

const Textos = ()=>{
    return <div>
       <h4>Este trámite le permite solicitar el ascenso de rango para usted o personas que dependan de su ministerio.</h4>
        <p>Tenga en cuenta que si usted se encuentra bajo un ministerio debe solicitar su ascenso al ministro correspondiente a su caso.</p>
        <br />
        <p>Recuerde que los ascensos se pueden solicitar pasados al menos 2 años de la última actualización de su rango.</p>
        <br />
        <p>Es requisito adjuntar el certificado analítico de estudios teológicos para ser aprobado.</p>
        <br />
    </div>
}





const Ejemplo = ()=>{

  const [datos,setDatos]=useState([])
  
  useEffect(()=>{
      traerDatos()
  },[])
  
  const traerDatos = async ()=>{
      try{
          const {data} = await Axios.get(`http://localhost:3020/api/tablasgenerales/abcd`)
          setDatos(data)
      }catch(err){
          alert(err)
      }
  }
  
      if(datos.length>0){
          return <div>
              {JSON.stringify(datos)}
          </div>
      }
      return <h1>ejemplo</h1>
  }

  const datosMinistroSeleccionado = (ministroSolicitud,usuario,dependientes)=>{
    if(dependientes && dependientes.length>0){
        if(ministroSolicitud!=usuario.id_obrero){
            const datosDependiente = dependientes.filter(item=>item.id==ministroSolicitud)
            return datosDependiente.length>0 ? datosDependiente[0] : null
        }else{
            return usuario
        }  
    }else{
        return usuario
    }
}