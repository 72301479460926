import Axios from 'axios';
import {getToken} from './auth-helpers';

export const abrirFormHabilitacionConvencion = ()=>{
    const token = getToken();
    
    const ruta = `${process.env.REACT_APP_ROOT}/api/online/autorizaciones/convencion/2024/${token}`;

    window.open(ruta,"_blank")
}

export function scrollTop(){
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }    

  export function scrollBottom(){
    document.body.scrollBottom = 0; // For Safari
    document.documentElement.scrollBottom = 0 // For Chrome, Firefox, IE and Opera
  }  

  export function hacerScroll(id){
    let element = document.getElementById(id);
    if(element){
        element.scrollIntoView();
    }
}

export function formatoMoneda(valor){
    const valorNumericoSeguro = Number(valor)
    const parteEntera = parseInt(valorNumericoSeguro)
    const parteDecimal = (valorNumericoSeguro - parteEntera) * 100
    const parteDecimalString = parteDecimal==0 ? '00' : parteDecimal.toString()
    // el primer regex no funciona en IPHONE o IPADS
    //const parteEnteraConPuntos = parteEntera.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")
    // Cambiamos este regex /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g por /\B(?=(\d{3})+(?!\d))/g para que funcione en iphones y ipads
    const parteEnteraConPuntos = parteEntera.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
   
    return `${parteEnteraConPuntos},${parteDecimalString}`
}

export function seleccionarTextoInput(id){
    document.getElementById(id).select()
}

export function hacerfocoEnPrimerInput(id){

    let idInterval =setInterval(() => {
        const element = document.getElementById(id);

        if (element){
            element.focus();
            clearInterval(idInterval)
        }
    }, 500);
}

export function hacerFoco(id){
    let idInterval =setInterval(() => {
        const element = document.getElementById(id);
    
        if (element){
            element.focus();
            clearInterval(idInterval)
        }
    }, 10);
}

export const createId = ()=>{
    const d = new Date();
    let dy = d.getDate()< 10 ? `0${d.getDate()}`: d.getDate();
    let mt = d.getMonth() < 10 ? `0${d.getMonth()}`: d.getMonth();
    let yr = d.getFullYear();
    let hs = d.getHours() < 10 ? `0${d.getHours()}`: d.getHours();
    let mn = d.getMinutes() < 10 ? `0${d.getMinutes()}`: d.getMinutes();
    let sc = d.getSeconds() < 10 ? `0${d.getSeconds()}`: d.getSeconds() ;
  
    const id = `${yr}${mt}${dy}${hs}${mn}${sc}`
    return id
  }

export  const doPostFile = async (file,usuario,identificador,extension) => {

    try{
      let form = new FormData()
//        form.append('upload', file, file.name);

        if(file==undefined || !file){
            return [false,`'No se encontró el parámetro file`]
        }
        if(usuario==undefined || !usuario){
            return [false,`'No se encontró el parámetro usuario`]
        } 
        if(identificador==undefined || !identificador){
            return [false,`'No se encontró el parámetro identificador`]
        }
        if(extension==undefined || !extension){
            return [false,`'No se encontró el parámetro extension`]
        } 

      // ATENCION: para que multers3 pueda identificar bien el content-type
      // debo enviar el archivo con extensión.
      // Si no, no puede identificar el conten-type y lo guarda como application/octect-stream
      // causando que cuando se genera el presigned  url para la lectura no se puede abrir el archivo sino
      // que lo descarga. Para poder leer e interpretar bien un pdf el content-type debe ser application/pdf
      // de otra manera no funciona la lectura, se descarga.
      // Envío el nombre con extensión pero en el backend el key del archivo va a ser el nombre sin la extensión.
//      form.append('upload', file, `${usuario.id_obrero}_analitico_${createId()}.pdf`);

// PRUEBO DE NO ENVIAR LA EXTENSION PARA EVITAR ANALIZAR EL ARCHIVO. SI FUNCIONA BIEN LO DEJAMOS ASI.
//      form.append('upload', file, `${usuario.id_obrero}_${identificador}_${createId()}.${extension}`);
      
      const identificador_por_entorno = process.env.REACT_APP_ENV==='dev' ? `${identificador}_devtesting` : identificador;
      /*NOTE - El nombre del archivo será por entorno para poder identificar los archivos que se pueden eliminar del space de DO*/
      form.append('upload', file, `${usuario.id_obrero}_${identificador_por_entorno}_${createId()}`);
 
      const result = await loadDataPromise(form)

//        return [true,result.url]
      return [true,result.key]
    
    }catch(err){
        return [false,`${err} ${file.name}`]
    }
}

const loadDataPromise = (form)=>{
    return new Promise(async (callbackExito, callbackError)=>{
      try {
//            const respuesta = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/upload`,form,{headers: { 'Content-Type': 'multipart/form-data' }})

/* Si hace falta enviar info adicional se puede enviar en el header
por ejemplo {headers: { 'Content-Type': 'multipart/form-data','id':'23865621' }}
no se puede enviar info extra en el form porque multers3 no toma los otros datos del form que no sea
el archivo. Si tuviese que enviar un formdata con muchos campos que no sean archivos si funciona.
Pero multers3 no los parsea.
Tampoco se puede enviar info en req.query o req.params en una peticion post.
La única manera de enviar info adicional a la petición post que procesa multer o multers3 para manipular
un archivo es a través del header.

En el front se agrega configuración del header con una key personalizada llamada id_ministro

{headers: { 'Content-Type': 'multipart/form-data','id_ministro':'23865621' }}

En el backend se toma así el campo id_ministro

req.headers.id_ministro

*/
        const respuesta = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/upload`,form,{headers: { 'Content-Type': 'multipart/form-data','id':'23865621' }})
        callbackExito(respuesta.data);
      } catch (error) {
        console.log(error)
        callbackError('Se produjo un eror al cargar el archivo. No es posibe adjuntar el documento');
      }
    })
 }


 export const eliminarArchivos = async (vectorFiles)=>{

    try{

        const respuesta = await Promise.all(vectorFiles.map(item=>{
            return Axios.delete(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/deletefile/${item}`)
        }))
        console.log(respuesta)
    }catch(err){
        console.log(err)
    }
 }


 export const esFotoBase64 = (foto='') =>{
    if(foto?.includes('==')){
        return true
    }else{
        return false
    }
 }

 export const traerUrl = async (key)=>{
    try{
        const {data} = await Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/pdf/${key}`)
        return(data.url)
    }catch(err){
        console.log(err)
        return(null)
 }
}

